import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';

import * as userApi from './../../api/user';

import { checkUserTokenUtil } from '../../utils/utils';

export class UserList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
            toSearch: '',
            user: {},
            ownref: '',
            phone: '',
            pages: 0,
            pagesArray: [],
            currentPage: 1,
            offset: 0,
            limit: 50
        }
    }

    callFetchCustomers = async (e) => {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        var data = {
            offset: this.state.offset,
            limit: this.state.limit,
            searchString: this.state.toSearch.toLowerCase()
        }
        await userApi.fetchCustomers(data)
            .then(res => {
                if (res.status == 200) {
                    this.setState({ users: res.data.users, pages: res.data.count % this.state.limit == 0 ? res.data.count / this.state.limit : parseInt(res.data.count / this.state.limit) + 1 }, () => {
                        var pagesArray = [];
                        for (var i = 0; i < this.state.pages; i++) {
                            pagesArray.push(i + 1)
                        }
                        this.setState({ pagesArray: pagesArray })
                        spinner.style.display = "none";
                    })
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            this.setState({ user: checkUserTokenUtilRes }, async () => {
                await this.callFetchCustomers();
            })
        }
    }

    searchParam = (e) => {
        e.preventDefault();
        this.setState({ currentPage: 1, offset: 0, limit: 50 }, () => {
            this.callFetchCustomers();
        })
    }

    resetSearch = e => {
        e.preventDefault();
        window.location.reload();
    }

    changePage = e => {
        var offset = this.state.limit * (e.target.value - 1)
        this.setState({ currentPage: e.target.value, offset: offset }, () => {
            this.callFetchCustomers();
        })
    }

    updateUserData = async (e) => {
        e.preventDefault();
        if (this.state.phone == 0 || this.state.phone == '' || this.state.phone == null || this.state.phone == undefined) {
            alert('Please veify the Phone number..!')
        } else {
            if (this.state.user.authority == 'admin') {
                if (window.confirm('Do you want to update the User..?')) {
                    let spinner = document.getElementById("spinneranimid");
                    spinner.style.display = "block";
                    const data = {
                        ownref: this.state.ownref,
                        phone: this.state.phone,
                    }

                    await userApi.updateCustomer(data)
                        .then(res => {
                            if (res.status == 200) {
                                alert('User Updated..!')
                                spinner.style.display = "none";
                                window.location.reload();

                            } else if (res.status == 402 && res.error == 'ownref wrong') {
                                alert('Invalid DS Code')
                                // window.location.reload();
                                spinner.style.display = "none";
                            } else if (res.status == 402 && res.error == 'phone already exists') {
                                alert('This Phone number already Exists..! Please enter another one.!')
                                // window.location.reload();
                                spinner.style.display = "none";
                            } else {
                                alert('Error Occured.. Please Try after some time')
                                // window.location.reload();
                                spinner.style.display = "none";
                            }
                        })
                        .catch((err) => {
                            alert('Error Occured.. Please Try after some time')
                            // window.location.reload();
                            spinner.style.display = "none";
                        })
                }
            } else {
                alert('You are not Authorised..!')
            }
        }
    }

    sendLink = async (e, user, action) => {
        e.preventDefault();

        if (window.confirm('Do you want to send the link..?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            var dataToSend = {
                user: user,
                action: action
            }

            await userApi.sendLinks(dataToSend)
                .then(res => {
                    if (res.status == 200) {
                        alert('Link Sent..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Check the Mobile Number')
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                })

        }

    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Users" parent="Users" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-2">Search User By</h5>
                                    <p>( firstname, lastname, email, phone, city, DS code )</p>
                                    <form onSubmit={this.searchParam}>
                                        <input style={{ maxWidth: '300px' }} className="form-control" id="searchParam" name="searchParam" onChange={(e) => this.setState({ toSearch: e.target.value })} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Search
                                        </button>

                                        <button type="btn" className="btn btn-primary mt-3 ml-3" onClick={this.resetSearch}>
                                            Reset
                                        </button>
                                    </form>

                                    <hr />

                                    <h5 className="mb-2">Update Phone Number</h5>
                                    <form onSubmit={this.updateUserData}>
                                        <label htmlFor='ownref'>DS Code* :</label>
                                        <input style={{ maxWidth: '300px' }} type='text' className="form-control mb-2" id="ownref" name="ownref" onChange={(e) => this.setState({ ownref: e.target.value })} required />
                                        <label htmlFor='phone'>New Phone* :</label>
                                        <input style={{ maxWidth: '300px' }} type='number' className="form-control" id="phone" name="phone" onChange={(e) => this.setState({ phone: e.target.value })} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Update Phone Number
                                        </button>

                                    </form>

                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.users.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Address</th>
                                                    <th>City</th>
                                                    <th>DS Code</th>
                                                    <th>Referred By</th>
                                                    <th>No. Of Downlines</th>
                                                    <th>Normal Balance</th>
                                                    <th>Plan Balance</th>
                                                    <th>Email Verification</th>
                                                    <th>isFirstOrder</th>
                                                    <th>Send Verification Link</th>
                                                    <th>Send Forget Password Link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.users.map((item, index) => {
                                                    return (
                                                        <tr key={index}>

                                                            <td>{item.id}</td>
                                                            <td>{item.firstname}&nbsp;{item.lastname}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.phone}</td>
                                                            <td>
                                                                <p>
                                                                    {item.firstname}&nbsp;{item.lastname}, <br />
                                                                    {item.address},<br />
                                                                    {item.city},&nbsp;{item.district} <br />
                                                                    {item.state},&nbsp;{item.pincode}. <br />
                                                                    {item.phone}<br />
                                                                    {item.email}<br />
                                                                </p>
                                                            </td>
                                                            <td>{item.city}</td>
                                                            <td><strong>{item.ownref}</strong></td>
                                                            <td style={{ textAlign: 'start' }}>
                                                                {item.referenceby != '' ?
                                                                    <div>
                                                                        DS&nbsp;Code:&nbsp;<strong>{item.referenceby}</strong><br />
                                                                        Name:&nbsp;<strong>{item.parent.firstname}&nbsp;{item.parent.lastname}</strong><br />
                                                                        Phone&nbsp;No.:&nbsp;<strong>{item.parent.phone}</strong>
                                                                    </div>
                                                                    : <div />
                                                                }

                                                            </td>
                                                            <td>{item.downlineCount}</td>
                                                            <td>₹ {item.normal_balance}</td>
                                                            <td style={{ minWidth: '200px', textAlign: 'start' }}>
                                                                <p>
                                                                    Total: ₹ {parseInt(item.ultrapremium_balance) + parseInt(item.premium_balance) + parseInt(item.light_balance) + parseInt(item.basic_balance)}<br />
                                                                    Ultra Premium: ₹ {item.ultrapremium_balance}<br />
                                                                    Premium: ₹ {item.premium_balance}<br />
                                                                    Light: ₹ {item.light_balance}<br />
                                                                    Basic: ₹ {item.basic_balance}<br />
                                                                </p>
                                                            </td>
                                                            <td>{item.confirmed ? 'Completed' : 'Pending'}</td>
                                                            <td>{item.isFirstOrder ? 'Yes' : 'No'}</td>
                                                            <td><a href='#' onClick={(e) => this.sendLink(e, item, 'verification')} className='aicon'><i className="fa fa-arrow-circle-o-right" /></a></td>
                                                            <td><a href='#' onClick={(e) => this.sendLink(e, item, 'password')} className='aicon'><i className="fa fa-arrow-circle-o-right" /></a></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <strong>No User Found</strong>
                                        </div>
                                    }
                                    <hr />
                                    <select className="form-control mt-3 mb-3" id="pages" name="pages" value={this.state.currentPage} onChange={this.changePage} style={{ maxWidth: '250px' }}>
                                        {this.state.pagesArray.map((item, index) =>
                                            <option key={index} value={item}>Page {item}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default UserList;
