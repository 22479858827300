import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as otherApi from './../../api/other';

export class ManageNotifications extends Component {

    constructor(props) {
        super(props)

        this.state = {
            notifications: [],
            title: '',
            description: ''
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.fetchNotifications()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ notifications: res.data.length ? res.data.reverse() : [] })
                    } else {
                        alert('Error Occured .. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured .. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    pushNotification = async (e) => {
        e.preventDefault();

        if (window.confirm('Do you want to Push the Notification ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                title: this.state.title,
                body: this.state.description,
                created_at: Date.now()
            }

            await otherApi.pushNotification(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Notification Pushed ..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }

    }

    deleteNotification = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Do you want to delete the Notification ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.deleteNotification({ id: id })
                .then((res) => {
                    if (res.status == 200) {
                        alert("Notification Deleted");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Manage Notifications" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <form onSubmit={this.pushNotification}>
                                        <label htmlFor='title'>Title* :</label>
                                        <input style={{ maxWidth: '450px' }} className="form-control mb-4" id="title" name="title" onChange={(e) => this.setState({ title: e.target.value })} required />
                                        <label htmlFor='descr'>Description* :</label>
                                        <textarea style={{ maxWidth: '450px' }} className="form-control mb-4" id="description" name="description" onChange={(e) => this.setState({ description: e.target.value })} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Push Notification
                                        </button>

                                    </form>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.notifications.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.notifications.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td style={{ textAlign: 'start' }}>{item.body}</td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteNotification(e, item.id)} className='aicon'><i className="fa fa-close" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Notification has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default ManageNotifications;
