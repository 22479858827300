import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as otherApi from './../../api/other';

export class AddTxn extends Component {

    constructor(props) {
        super(props)

        this.state = {
            special_txns: [],
            sponsor: '',
            amount: 0,
            type: '',
            wallet: '',
            notes: '',
            user: {}
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            this.setState({ user: checkUserTokenUtilRes }, async () => {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";
                await otherApi.fetchSpecialTxns()
                    .then(res => {
                        if (res.status == 200) {
                            this.setState({ special_txns: res.data.reverse() })
                        } else {
                            alert('Error Occured.. Please Try after some time')
                        }
                        spinner.style.display = "none";
                    })
                    .catch((err) => {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    })
            })
        }
    }

    addTxn = async (e) => {
        e.preventDefault();

        if (window.confirm('Do you Want to add the transaction..?')) {
            let userData = {
                ownref: this.state.sponsor,
                amount: this.state.amount,
                type: this.state.type,
                wallet: this.state.wallet,
            }
            let txnData = {
                sponsor_id: this.state.sponsor,
                amount: this.state.amount,
                type: this.state.type,
                wallet: this.state.wallet,
                txnUser: this.state.user,
                date: new Date(),
                normal_balance: 0,
                ultrapremium_balance: 0,
                premium_balance: 0,
                light_balance: 0,
                basic_balance: 0,
                notes: this.state.notes
            }

            let data = {
                userData: userData,
                txnData: txnData
            }

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block"

            await otherApi.addSpecialTxn(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Transaction Added ..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'User Does Not Exists') {
                        alert('Please verify the DS code..! User not found')
                        spinner.style.display = "none";
                    } else if (res.status == 402 && res.error == 'Insufficient Funds In User Wallet') {
                        alert('User does not have sufficient amount for DEBIT...!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Adding the Transaction.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Adding the Transaction.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }

    }

    render() {

        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <Fragment>
                <Breadcrumb title="Add Transaction" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    {this.state.user.authority == 'admin' ?
                                        <form onSubmit={this.addTxn}>
                                            <label htmlFor='sponsor'>DS Code :</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control mb-4" id="sponsor" name="sponsor" onChange={(e) => this.setState({ sponsor: e.target.value })} required />

                                            <label htmlFor='amount'>Amount :</label>
                                            <input type='number' style={{ maxWidth: '350px' }} className="form-control mb-4" id="amount" min="1" name="amount" onChange={(e) => this.setState({ amount: e.target.value })} required />

                                            <label htmlFor='type'>Type :</label>
                                            <select style={{ maxWidth: '350px' }} className="form-control mb-4" id="type" name="type" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })} required >
                                                <option value="">--Select Type--</option>
                                                <option value="Credit">Credit</option>
                                                <option value="Debit">Debit</option>
                                            </select>

                                            <label htmlFor='wallet'>Select Wallet :</label>
                                            <select style={{ maxWidth: '350px' }} className="form-control mb-4" id="wallet" name="wallet" value={this.state.wallet} onChange={(e) => this.setState({ wallet: e.target.value })} required>
                                                <option value="">--Select Wallet--</option>
                                                <option value="normal">Normal</option>
                                                <option value="ultrapremium">Ultra Premium</option>
                                                <option value="premium">Premium</option>
                                                <option value="light">Light</option>
                                                <option value="basic">Basic</option>
                                            </select>

                                            <label htmlFor='descr'>Notes :</label>
                                            <textarea style={{ maxWidth: '450px' }} className="form-control mb-4" id="notes" name="notes" onChange={(e) => this.setState({ notes: e.target.value })} />

                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add Transaction
                                            </button>
                                        </form>
                                        :
                                        <div>You are not authorised for these transactions..!</div>
                                    }
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.special_txns.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Made To</th>
                                                    <th>Made By</th>
                                                    <th>Amount</th>
                                                    <th>Type</th>
                                                    <th>Wallet</th>
                                                    <th>Date</th>
                                                    <th>Normal Wallet</th>
                                                    <th>Plan Wallet</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.special_txns.map((item, index) => {
                                                    return (
                                                        <tr key={index}>

                                                            <td>{index + 1}</td>
                                                            <td>{item.sponsor_id}</td>
                                                            <td style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', textAlign: 'start' }}>
                                                                Name:&nbsp;&nbsp;<strong>{JSON.parse(item.txnUser).name}</strong> <br />
                                                                Email:&nbsp;&nbsp;<strong>{JSON.parse(item.txnUser).email}</strong> <br />
                                                                Phone:&nbsp;&nbsp;<strong>{JSON.parse(item.txnUser).phone}</strong> <br />
                                                            </td>
                                                            <td><strong>₹ {item.amount}</strong></td>
                                                            <td>{item.type}</td>
                                                            <td><strong>{item.wallet.toUpperCase()}</strong></td>
                                                            <td>{days[new Date(item.date).getDay()] + ', ' + monthNames[new Date(item.date).getMonth()] + ' ' + new Date(item.date).getDate() + ', ' + new Date(item.date).getFullYear()}</td>
                                                            <td><strong>₹ {item.normal_balance}</strong></td>
                                                            <td style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', textAlign: 'start' }}>
                                                                Total:&nbsp;&nbsp;<strong>₹ {parseInt(item.ultrapremium_balance) + parseInt(item.premium_balance) + parseInt(item.light_balance) + parseInt(item.basic_balance)}</strong> <br />
                                                                Ultra&nbsp;Premium:&nbsp;&nbsp;<strong>₹ {item.ultrapremium_balance}</strong> <br />
                                                                Premium:&nbsp;&nbsp;<strong>₹ {item.premium_balance}</strong> <br />
                                                                Light:&nbsp;&nbsp;<strong>₹ {item.light_balance}</strong> <br />
                                                                Basic:&nbsp;&nbsp;<strong>₹ {item.basic_balance}</strong> <br />
                                                            </td>
                                                            <td style={{ textAlign: 'start' }}>{item.notes}</td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Transaction has been made yet.</p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default AddTxn;
