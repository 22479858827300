import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import StarRatingComponent from "react-star-rating-component";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Breadcrumb from "./../common/breadcrumb";

import * as productApi from "./../../api/product";

import { toggleDeleteProductCart } from "./../../actions";

import { checkUserTokenUtil } from "../../utils/utils";

export class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      nav1: null,
      nav2: null,
      filterclrs: ""
    };
  }

  componentDidMount = async () => {
    await productApi.fetchPrdColor({ id: this.props.item.id })
      .then((response) => {
        if (response?.data?.productColorPrdColor) {
          this.setState({ filterclrs: response?.data?.productColorPrdColor })
        }
      })
      .catch(error => {
        console.log(error);
      })
    const checkUserTokenUtilRes = await checkUserTokenUtil();
    if (checkUserTokenUtilRes) {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2,
      });
    }
  }

  deleteItem = async (e) => {
    e.preventDefault();

    if (window.confirm('Delete the Product ?')) {

      const prodId = {
        id: this.props.item.id
      }

      await productApi.deleteProduct(prodId)
        .then(res => {
          if (res.status == 200) {
            alert('Product Deleted')
            this.props.toggleDeleteProductCart(this.props.item)
            window.location = process.env.PUBLIC_URL + '/products/product-list'
          } else if (res.status == 405 && res.data.error == 'Product Does Not Exists') {
            alert('Product Does Not Exists')
            window.location.reload();
          } else {
            alert('Something went wrong')
            window.location.reload();
          }
        })
        .catch((err) => {
          alert('Something went wrong')
          window.location.reload();
        })

    }
  }

  render() {
    const { item, symbol } = this.props;

    return (
      <Fragment>
        <Breadcrumb title="Product Detail" parent="Physical" />

        <div className="container-fluid">
          <div className="card">
            <div className="row product-page-main card-body">
              <div className="col-xl-4">
                <Slider
                  asNavFor={this.state.nav2}
                  ref={(slider) => (this.slider1 = slider)}
                  className="product-slider"
                >
                  {JSON.parse(item.pictures).map((picture, index) =>
                    <div className="item" key={index}>
                      <img className="img-fluid" src={`https://shivshahinetwork.com/${picture}`} alt="" />
                    </div>
                  )}

                </Slider>

                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={JSON.parse(item.pictures).length}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  className="small-slick"
                >

                  {JSON.parse(item.pictures).length > 1 ?
                    JSON.parse(item.pictures).map((picture, index) =>
                      <div className="item" key={index}>
                        <img className="img-fluid" src={`https://shivshahinetwork.com/${picture}`} alt="" />
                      </div>
                    ) :
                    ''
                  }

                </Slider>
              </div>
              <div className="col-xl-8">
                <div className="product-page-details product-right mb-0">
                  <h4 style={{ color: '#c96' }}>Product ID : {item.id}</h4>
                  <h3>Sku : <strong style={{ color: 'black' }}>{item.sku}</strong></h3>
                  <hr />
                  <h2>{item.name}</h2>
                  <h3>{item.category}</h3>
                  <div style={{ fontSize: 27, height: 31 }}>
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={item.ratings}
                    />
                  </div>
                  <hr />
                  <h6 className="product-title">Other Info</h6>
                  <p>
                    {item.shortDesc}
                  </p>
                  <div className="product-price digits mt-2" style={{ display: 'flex' }}>
                    <h6>Color:&nbsp;&nbsp;&nbsp;</h6>
                    <div className="color-display" style={{ backgroundColor: item.color }} />
                  </div>
                  <div className="product-price digits mt-2" style={{ display: 'flex' }}>
                    <h6>
                      <b>Filter Color:</b>&nbsp;{this.state.filterclrs}</h6>
                  </div>
                  <div className="product-price digits mt-2">
                    <h6 className="product-title">Pricing:</h6>
                    <h6>
                      <b>Price:</b>{symbol}&nbsp;{item.price}
                    </h6>
                    <h6>
                      <b>Discount:</b> {item.off}%
                    </h6>
                  </div>
                  <hr />
                  <div className="add-product-form">
                    <h6 className="product-title">Stocks:</h6>
                    <h6>
                      <b>New</b>: {(item.new).toString()}
                    </h6>
                    <h6>
                      <b>Stock (6 Yard)</b>: {JSON.parse(item.variants)[0].stock}
                    </h6>
                    {/* <h6>
                      <b>Stock (9 Yard)</b>: {JSON.parse(item.variants)[1].stock}
                    </h6>
                    <h6>
                      <b>Stock (11 Yard)</b>: {JSON.parse(item.variants)[2].stock}
                    </h6> */}
                  </div>
                  <hr />
                  <div className="m-t-15">
                    <h6 className="product-title">Additional Information:</h6>
                    <p>
                      {item.additionalInfo}
                    </p>
                  </div>
                  <hr />
                  <div className="m-t-15">
                    <h6 className="product-title">Product Description:</h6>
                    <p>
                      {item.description}
                    </p>
                  </div>
                  <div className="m-t-15">
                    <Link to={`${process.env.PUBLIC_URL}/products/update-product/${item.id}`}>
                      <button className="btn btn-primary m-r-10" type="button">
                        Update Details
                      </button>
                    </Link>

                    <button className="btn btn-secondary" type="button" onClick={this.deleteItem}>
                      Delete
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find(el => el.id == productId),
    symbol: state.data.symbol,
    delete_cart: state.filters.delete_cart
  }
}

export default connect(mapStateToProps, { toggleDeleteProductCart })(ProductDetail);
