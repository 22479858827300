import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Edit, Pocket, Scissors, Trash2 } from "react-feather";
import StarRatingComponent from "react-star-rating-component";

import Breadcrumb from "./../common/breadcrumb";

import * as productApi from "./../../api/product";

import { toggleDeleteProductCart } from "./../../actions";

import { checkUserTokenUtil } from "../../utils/utils";

const findIndex = function (array, cb) {
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (true === cb(array[i]))
        return i;
    }
  }
  return -1;
}

export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      category_filter: '',
      // category_filter: this.props.cats[0].category,
      pages: 0,
      pagesArray: [],
      currentPage: 1,
      offset: 0,
      limit: 98,
      stock_filter: 'Total Stock'
    };
  }

  callFetchProducts = async (e) => {
    let spinner = document.getElementById("spinneranimid");
    spinner.style.display = "block";
    var data = {
      offset: this.state.offset,
      limit: this.state.limit,
      category_filter: this.state.category_filter,
      stock_filter: this.state.stock_filter
    }
    await productApi.fetchProducts(data)
      .then(res => {
        if (res.status == 200) {
          this.setState({ products: res.data.products, pages: res.data.count % this.state.limit == 0 ? res.data.count / this.state.limit : parseInt(res.data.count / this.state.limit) + 1 }, () => {
            var pagesArray = [];
            for (var i = 0; i < this.state.pages; i++) {
              pagesArray.push(i + 1)
            }
            this.setState({ pagesArray: pagesArray }, () => {
              setTimeout(() => {
                spinner.style.display = "none";
              }, 250);
            })
          })
        } else {
          alert('Error Occured.. Please Try after some time')
          spinner.style.display = "none";
        }
      })
      .catch((err) => {
        alert('Error Occured.. Please Try after some time')
        spinner.style.display = "none";
      })
  }

  componentDidMount = async () => {
    const checkUserTokenUtilRes = await checkUserTokenUtil();
    if (checkUserTokenUtilRes) {
      await this.callFetchProducts();
    }
  }

  changeCat = e => {
    this.setState({ category_filter: e.target.value, currentPage: 1, offset: 0, limit: 98 }, async () => {
      await this.callFetchProducts();
    })
  }

  onChangeStockFilter = e => {
    this.setState({ stock_filter: e.target.value, currentPage: 1, offset: 0, limit: 98 }, async () => {
      await this.callFetchProducts();
    })
  }

  changePage = e => {
    var offset = this.state.limit * (e.target.value - 1)
    this.setState({ currentPage: e.target.value, offset: offset }, async () => {
      await this.callFetchProducts();
    })
  }


  deleteProd = async (e, product) => {
    e.preventDefault();

    if (window.confirm('Delete the Product ?')) {

      const prodId = {
        id: product.id
      }

      await productApi.deleteProduct(prodId)
        .then(res => {
          if (res.status == 200) {
            alert('Product Deleted')
            this.props.toggleDeleteProductCart(product)
            window.location.reload();
          } else if (res.status == 405 && res.data.error == 'Product Does Not Exists') {
            alert('Product Does Not Exists')
            window.location.reload();
          } else {
            alert('Something went wrong')
            window.location.reload();
          }
        })
        .catch((err) => {
          alert('Something went wrong')
          window.location.reload();
        })

    }
  }

  addtoDeleteCart = (e, product) => {
    e.preventDefault();
    this.props.toggleDeleteProductCart(product)
  }

  render() {
    const styles = {
      "margin-top": "15px"
    };
    const { symbol, delete_cart } = this.props;
    const stockArray = ['Total Stock', 'In Stock', 'Out of Stock', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+']
    return (
      <Fragment>
        <Breadcrumb title="Product List" parent="Physical" />
        <div id="spinneranimid" className="overlay-spinner">
          <div className="cssload-whirlpool" />
        </div>
        <div className="container-fluid">

          <div className="card-header mb-3" >
            <h5 className="mb-3">Manage Products</h5>
            <div className="filters-custom" id='SF'>
              <div className='order-status-filter'>
                <select className="form-control mt-3 mb-3" id="pages" name="pages" value={this.state.category_filter} onChange={this.changeCat} style={{ maxWidth: '250px' }}>
                  <option value=''>All Categories</option>
                  {this.props.cats.map((category, index) =>
                    <option key={index} value={category.category}>{category.category}</option>
                  )}
                </select>
              </div>
              <div className='order-status-filter'>
                <select className="form-control mt-3 mb-3" id="stock_filter" name="stock_filter" value={this.state.stock_filter} onChange={this.onChangeStockFilter} style={{ maxWidth: '250px' }}>
                  {stockArray.map((stock, index) =>
                    <option key={index} value={stock}>{stock}</option>
                  )}
                </select>
              </div>
            </div>
          </div>

          <div className="row products-admin ratio_asos">
            {this.state.products.map((products, index) => {
              return (
                <div className="col-xl-3 col-sm-6" key={index}>
                  <div className="card">
                    <div className="products-admin">
                      <Link to={`${process.env.PUBLIC_URL}/products/product-detail/${products.id}`}>
                        <div className="card-body product-box">
                          <div className="img-wrapper">

                            <div className="front">
                              <a className="bg-size">
                                <img
                                  className="img-fluid blur-up bg-img lazyloaded"
                                  src={`https://shivshahinetwork.com/${JSON.parse(products.pictures)[0]}`}
                                />
                              </a>
                              <div className="product-hover">
                                <ul>
                                  <li>
                                    <Link to={`${process.env.PUBLIC_URL}/products/update-product/${products.id}`}>
                                      <button className="btn fancybtn" type="button">
                                        <Edit className="editBtn" />
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <button className="btn fancybtn" type="button" onClick={e => this.deleteProd(e, products)}>
                                      <Scissors className="deleteBtn" />
                                    </button>
                                  </li>
                                  <li>
                                    <button className="btn fancybtn" type="button" onClick={e => this.addtoDeleteCart(e, products)}>
                                      <Trash2 className="deleteBtn" style={{ display: findIndex(delete_cart, item => item.id == products.id) == -1 ? '' : 'none' }} />
                                      <Pocket className="deleteBtn" style={{ display: findIndex(delete_cart, item => item.id == products.id) == -1 ? 'none' : '' }} />
                                    </button>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-detail" style={styles}>
                            <a className="pt-2">
                              {" "}
                              <h6>Sku: <span style={{ color: 'black' }}>{products.sku}</span></h6>
                            </a>
                            <div style={{ fontSize: 20, height: 31 }}>
                              <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                value={parseInt(products.ratings)}
                              />
                            </div>
                            <h4>
                              <strong>{symbol}{products.price}{" "}</strong>
                            </h4>
                            <a>
                              {" "}
                              <h6 style={{ color: 'black' }}><strong>{products.name}</strong></h6>
                            </a>

                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          <select className="form-control mt-3 mb-3" id="pages" name="pages" value={this.state.currentPage} onChange={this.changePage} style={{ maxWidth: '250px' }}>
            {this.state.pagesArray.map((item, index) =>
              <option key={index} value={item}>Page {item}</option>
            )}
          </select>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.data.products,
  symbol: state.data.symbol,
  cats: state.data.cats,
  filterclrs: state.data.filterclrs,
  delete_cart: state.filters.delete_cart
})

export default connect(mapStateToProps, { toggleDeleteProductCart })(ProductList);