import axios from "axios";

const TIMEOUT = 100;

const _products = axios.post(`${process.env.PUBLIC_URL}/productrequest/fetchindexedproducts`, {}).then((response) => {
  return response.data.data;
});

const _cats = axios.post(`${process.env.PUBLIC_URL}/productrequest/fetchcategories`, {}).then((response) => {
  return response.data.data;
});

const _filterclrs = axios.post(`${process.env.PUBLIC_URL}/productrequest/fetchcolors`, {}).then((response) => {
  return response.data.data;
});

const _trackings = axios.post(`${process.env.PUBLIC_URL}/orderrequest/fetchtrackings`, {}).then((response) => {
  return response.data.data;
});

export default {
  getProducts: (cb, timeout) =>
    setTimeout(() => cb(_products), timeout || TIMEOUT),
  getCats: (cb, timeout) =>
    setTimeout(() => cb(_cats), timeout || TIMEOUT),
  getFilterclrs: (cb, timeout) =>
    setTimeout(() => cb(_filterclrs), timeout || TIMEOUT),
  getTrackings: (cb, timeout) =>
    setTimeout(() => cb(_trackings), timeout || TIMEOUT),
};
