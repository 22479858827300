import * as types from '../constants/ActionTypes'
import { findIndex } from "./../utils/utils";

const filtersReducerDefaultState = {
    brand: ["Hindu Gods", "Buddha", "lifestyle"],
    value: { min: 50, max: 10000 },
    sortBy: "",
    // order_status: ['Placed', 'Processed', 'Packed', 'Shipped', 'Delivered'],
    order_status: [],
    delete_cart: [],
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    // console.log('Action Result');
    // console.log(action);
    switch (action.type) {
        case types.FILTER_BRAND:
            return {
                ...state,
                brand: action.brand
            };
        case types.FILTER_COLOR:
            return {
                ...state,
                color: action.color
            };
        case types.FILTER_PRICE:
            return {
                ...state,
                value: { min: action.value.value.min, max: action.value.value.max }
            };
        case types.SORT_BY:
            return {
                ...state,
                sortBy: action.sort_by
            };
        case types.ORDER_STATUS_FILTER:
            let order_status = Object.assign([], state.order_status);
            let index = findIndex(order_status, item => item == action.order_status);
            if (-1 === index) {
                order_status.push(action.order_status);
            } else {
                order_status.splice(index, 1);
            }
            return {
                ...state,
                order_status: order_status
            };
        case types.DELETE_PRODUCT_CART:
            let delete_cart = Object.assign([], state.delete_cart);
            let index2 = findIndex(delete_cart, item => item.id == action.delete_cart.id);
            if (-1 === index2) {
                delete_cart.push(action.delete_cart);
            } else {
                delete_cart.splice(index2, 1);
            }
            return {
                ...state,
                delete_cart: delete_cart
            };
        default:
            return state;
    }
}

export default filtersReducer;