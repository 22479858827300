import axios from 'axios';

export const fetchProducts = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchproducts`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addCategory = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/addcategory`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const editCategory = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/editcategory`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteCategory = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/deletecategory`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addProduct = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/addproduct`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const editProduct = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/editproduct`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteProduct = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/deleteproduct`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteMultipleProducts = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/deletemultipleproducts`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const findSingleProduct = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/findSingleProduct`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchColors = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchcolors`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addColor = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/addcolor`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const editColor = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/editcolor`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteColor = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/deletecolor`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchPrdColor = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchprdcolor`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};