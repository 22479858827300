import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as userApi from './../../api/user';

export class RechargeRequests extends Component {

    constructor(props) {
        super(props)

        this.state = {
            requests: [],
            status: 'Requested'
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await userApi.fetchRechargeRequests({ status: this.state.status })
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ requests: res.data })
                    } else {
                        alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    changeSearch = e => {
        this.setState({ status: e.target.value }, async () => {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await userApi.fetchRechargeRequests({ status: this.state.status })
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ requests: res.data })
                    } else {
                        alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    spinner.style.display = "none";
                })
        })
    }

    onChangeStatus = async (e, id, status, amount, plan, userid, user) => {
        e.preventDefault();

        if (e.target.value != status && status != 'Approved') {
            if (window.confirm('Do you want to update the status ?')) {

                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                var userObj = JSON.parse(user)

                if (plan == "Basic Recharge") {
                    var settlements = [
                        { profit: parseInt(0.15 * amount) + parseInt(amount), ownref: userObj.ownref },
                        { profit: parseInt(0.05 * amount), ownref: userObj.referenceby != '' ? userObj.parent.ownref : '' },
                        { profit: parseInt(0.02 * amount), ownref: userObj.referenceby != '' ? userObj.parent.referenceby != '' ? userObj.parent.parent.ownref : '' : '' }
                    ]
                } else if (plan == "Light Recharge") {
                    var settlements = [
                        { profit: parseInt(0.18 * amount) + parseInt(amount), ownref: userObj.ownref },
                        { profit: parseInt(0.03 * amount), ownref: userObj.referenceby != '' ? userObj.parent.ownref : '' },
                        { profit: parseInt(0.01 * amount), ownref: userObj.referenceby != '' ? userObj.parent.referenceby != '' ? userObj.parent.parent.ownref : '' : '' }
                    ]
                } else if (plan == "Premium Recharge") {
                    var settlements = [
                        { profit: parseInt(0.20 * amount) + parseInt(amount), ownref: userObj.ownref },
                        { profit: parseInt(0.01 * amount), ownref: userObj.referenceby != '' ? userObj.parent.ownref : '' },
                        { profit: parseInt(0.01 * amount), ownref: userObj.referenceby != '' ? userObj.parent.referenceby != '' ? userObj.parent.parent.ownref : '' : '' }
                    ]
                } else {
                    var settlements = [
                        { profit: parseInt(0.20 * amount) + parseInt(amount), ownref: userObj.ownref },
                        { profit: parseInt(0.01 * amount), ownref: userObj.referenceby != '' ? userObj.parent.ownref : '' },
                        { profit: parseInt(0.01 * amount), ownref: userObj.referenceby != '' ? userObj.parent.referenceby != '' ? userObj.parent.parent.ownref : '' : '' }
                    ]
                }

                var txnArray = [];
                for (var y = 0; y < 4; y++) {
                    var txnObj = {
                        user_id: userid,
                        txn_id: '',
                        date: new Date(),
                        from_ownref: 'Shivshahi Network',
                        to_ownref: '',
                        amount: 0,
                        type: 'Recharge Profit',
                        order_id: '',
                        name: userObj.firstname + ' ' + userObj.lastname,
                        source: 'To - Normal_Wallet',
                        normal_balance: 0,
                        ultrapremium_balance: 0,
                        premium_balance: 0,
                        light_balance: 0,
                        basic_balance: 0,
                        payment_status: 'Completed',
                    }
                    var result = '';
                    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
                    var charactersLength = characters.length;
                    for (var i = 0; i < 12; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));

                    if (y == 0) {
                        txnObj.txn_id = 'RPR' + '_' + result + y
                        txnObj.from_ownref = settlements[0].ownref
                        txnObj.to_ownref = 'Shivshahi Network'
                        txnObj.amount = amount
                        txnObj.type = 'Recharge'
                        txnObj.source = 'From - Other'
                    } else if (y == 1) {
                        txnObj.txn_id = 'RPR' + '_' + result + y
                        txnObj.to_ownref = settlements[0].ownref
                        txnObj.amount = settlements[0].profit
                        txnObj.source = 'To - Recharge_Wallet'
                    } else if (y == 2) {
                        txnObj.txn_id = 'RPR' + '_' + result + y
                        txnObj.to_ownref = settlements[1].ownref
                        txnObj.amount = settlements[1].profit
                    } else if (y == 3) {
                        txnObj.txn_id = 'RPR' + '_' + result + y
                        txnObj.to_ownref = settlements[2].ownref
                        txnObj.amount = settlements[2].profit
                    }
                    if (txnObj.to_ownref != '') {
                        txnArray.push(txnObj)
                    }
                }

                const request = {
                    id: id,
                    status: e.target.value,
                    amount: amount,
                    plan: plan,
                    user_id: userid,
                    // settlements: settlements,
                    transactions: txnArray
                }

                await userApi.approveRechargeRequest(request)
                    .then((res) => {
                        if (res.status == 200) {
                            alert('Status Updated ..!')
                            window.location.reload();
                            spinner.style.display = "none";
                        } else {
                            alert('Something Went Wrong ..!')
                            window.location.reload();
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    })
            }
        }
    }

    viewReceipts = (receipts) => {
        JSON.parse(receipts).map((receipt, index) => {
            window.open(`https://shivshahinetwork.com/assets/images/recharge-receipts/${receipt.filename}`);
        })
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Recharge Requests" parent="Requests" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <select className="form-control" id="searchparam" name="searchparam" value={this.state.status} onChange={this.changeSearch} style={{ maxWidth: '250px' }}>
                                        <option value="Requested">Requested</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </select>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.requests.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Plan</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Receipt</th>
                                                    <th>Status</th>
                                                    <th>Update Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.requests.map((item, index) => {


                                                    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                                    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                                                    var date = new Date(item.date);
                                                    var dateFinal = days[date.getDay()] + ', ' + monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

                                                    return (
                                                        <tr key={index}>

                                                            <td>{index + 1}</td>
                                                            <td>{JSON.parse(item.user).firstname}&nbsp;{JSON.parse(item.user).lastname}</td>
                                                            <td>{JSON.parse(item.user).email}</td>
                                                            <td>{JSON.parse(item.user).phone}</td>
                                                            <td>{item.plan}</td>
                                                            <td>₹ {item.amount}</td>
                                                            <td>{dateFinal}</td>
                                                            <td><strong style={{ fontSize: '15px', color: '#c96', cursor: 'pointer' }} onClick={() => this.viewReceipts(item.receipt)}>View Receipt(s)</strong></td>
                                                            <td style={{ color: item.status == "Approved" ? 'green' : item.status == "Requested" ? '' : 'red' }}><strong>{item.status}</strong></td>
                                                            <td>
                                                                <select className="form-control" id="statuschange" name="status" value={item.status} onChange={(e) => this.onChangeStatus(e, item.id, item.status, item.amount, item.plan, item.user_id, item.user)} style={{ minWidth: '150px' }}>
                                                                    <option value="Requested">Requested</option>
                                                                    <option value="Approved">Approved</option>
                                                                    <option value="Cancelled">Cancelled</option>
                                                                </select>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Request has been made Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default RechargeRequests
