import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { Home } from 'react-feather';

import logomonogram from '../../assets/images/logo/android-chrome-512x512.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { checkUserTokenUtil } from '../../utils/utils';

export class Login extends Component {

    componentDidMount = async () => {
        await checkUserTokenUtil();
    }

    render() {
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 card-left">
                                    <div className="card bg-primary">
                                        <div className="svg-icon">
                                            <img src={logomonogram} alt="" className="Img-fluid" />
                                        </div>
                                        <div>
                                            <div>
                                                <h3>Shivshahi Network</h3>
                                                <p>घरा घरा मध्ये उद्योजग आणि एक भक्कम नेता तयार झाला पाहिजे। शिवशाही मध्ये सहभागीं असलेल्या प्रत्येक व्यक्तीचा व्यवसाय सोबतच ,शिक्षण कौशल्य, नेतृत्व गुणवत्ता, निर्भय वक्ता, प्रभावी व्यक्तिमत्वा चा सर्वांगी विकास।</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <LoginTabset />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="https://shivshahinetwork.com" target="_blank" rel="noopener noreferrer" className="btn btn-primary back-btn"><Home />Home</a>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Login
