import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';

import Breadcrumb from '../common/breadcrumb';

import * as orderApi from './../../api/order';

import { checkUserTokenUtil } from '../../utils/utils';

export class AddTracking extends Component {

    constructor(props) {
        super(props)

        this.state = {
            trackings: this.props.trackings,
            service: '',
            link: '',
        }
    }

    componentDidMount = async () => {
        await checkUserTokenUtil();
    }

    addTracking = async (e) => {
        e.preventDefault();
        if (window.confirm('Do you want to Add the Tracking ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const trackingData = {
                service: this.state.service,
                link: this.state.link,
            }

            await orderApi.addTracking(trackingData)
                .then(res => {
                    if (res.status == 200) {
                        alert('Tracking Added ..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Adding the Tracking.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Adding the Tracking.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }

    }

    deleteTracking = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Do you want to delete the Tracking ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            await orderApi.deleteTracking({ id: id })
                .then((res) => {
                    if (res.status == 200) {
                        alert("Tracking Deleted");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Tracking Does Not Exists') {
                        alert('This Tracking does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the Tracking.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the Tracking.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Manage Trackings" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <form onSubmit={this.addTracking}>
                                        <label htmlFor='service'>Service :</label>
                                        <input style={{ maxWidth: '350px' }} className="form-control mb-4" id="service" name="service" onChange={(e) => this.setState({ service: e.target.value })} required />
                                        <label htmlFor='link'>Tracking Page Link :</label>
                                        <input style={{ maxWidth: '350px' }} className="form-control mb-4" id="link" name="link" onChange={(e) => this.setState({ link: e.target.value })} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Add Tracking
                                        </button>

                                    </form>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.trackings.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Service</th>
                                                    <th>Link</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.trackings.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.service}</td>
                                                        <td><a href={item.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: '14px' }} className='alink'>{item.link}</a></td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteTracking(e, item.id)} className='aicon'><i className="fa fa-close" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Tracking has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

const mapStateToProps = (state) => ({
    trackings: state.data.trackings,
})

export default connect(mapStateToProps)(AddTracking);
