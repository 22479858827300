import React, { Component, Fragment } from 'react';
import { UserCheck, Repeat, Box } from 'react-feather';
import axios from 'axios';

import Breadcrumb from './../common/breadcrumb';

import { checkUserTokenUtil } from '../../utils/utils';

export class ShortUrlService extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clientData: {},
            payments: [],
            total_monthly_hits: 0,
            total_monthly_urls: 0
        }
    };

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            if (checkUserTokenUtilRes.authority != 'admin') {
                alert('You are not authorised..!')
                this.props.history.push(`/settings/profile`)
            } else {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                const data = {
                    secret_key: '$2b$10$gGLhxotk2OhcFv/eTBqPpuGYKFQ0CyikZbD9Y4mRmUDOKLSzNgKR6'
                }
                await axios.post(`https://rexr.in/shortenurlrequest/getclientdata`, data)
                    .then(res => {
                        if (res.data.status == 'success') {
                            this.setState({
                                clientData: res.data.data.clientData,
                                payments: res.data.data.payments,
                                total_monthly_hits: res.data.data.total_monthly_hits,
                                total_monthly_urls: res.data.data.total_monthly_urls
                            }, () => {
                                spinner.style.display = "none";
                            })
                        } else {
                            alert('something went wrong')
                            spinner.style.display = "none";
                        }
                    })
                    .catch(err => {
                        alert('something went wrong')
                        spinner.style.display = "none";
                    })
            }
        }
    }

    render() {
        return (

            <Fragment>
                <Breadcrumb title="Short URL Service" parent="Dashboard" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">

                    <div className='row'>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-md-6">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-cust card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-3">
                                            <div className="align-self-center text-center"><UserCheck className="font-cust" /></div>
                                        </div>
                                        <div className="media-body col-9">
                                            <h5 className="mb-1"><span className='font-koho'>Name: </span><strong className='font-koho'>{this.state.clientData.client_name}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>Customer ID: </span><strong className='font-koho'>{this.state.clientData.customer_id}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>Phone: </span><strong className='font-koho'>{this.state.clientData.client_phone}</strong></h5>
                                            <h5 className="mb-0"><span className='font-koho'>Joining Date: </span><strong className='font-koho'>{new Date(parseInt(this.state.clientData.createdAt)).toDateString()}</strong></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-md-6">
                            <div className="card o-hidden  widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-3">
                                            <div className="align-self-center text-center"><Box className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-9">
                                            <h5 className="mb-1"><span className='font-koho'>Total Monthly URLs: </span><strong className='font-koho'>{this.state.total_monthly_urls}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>URL Rate: </span><strong className='font-koho'>₹ 0.60</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>Total Monthly Hits: </span><strong className='font-koho'>{this.state.total_monthly_hits}</strong></h5>
                                            <h5 className="mb-0"><span className='font-koho'>Hit Rate: </span><strong className='font-koho'>₹ 0.20</strong></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className='text-center mt-3 mb-4 font-koho'>Billing</h2>
                    <div className="row">
                        {this.state.payments.length ?
                            this.state.payments.map((bill, index) =>
                                <div className="col-xl-4 col-lg-6 col-sm-12 col-md-6" key={index}>
                                    <div className="card order-graph sales-carousel">
                                        <div className="card-header">
                                            <h6>{bill.month_year}</h6>
                                            <h3 className="font-koho"><small>Bill:</small> <strong style={{ color: 'green' }}>₹ {bill.billing_amount}</strong></h3>
                                            <p className="font-koho">{new Date(parseInt(bill.createdAt)).toDateString()}</p>
                                        </div>
                                        <div className="card-body">
                                            <div className="media">
                                                <div className="media-body">
                                                    <span className='font-koho'>Monthly Urls Hits</span>
                                                    <h2 className="mb-0 font-koho font-secondary">{bill.monthly_hits}</h2>
                                                    <p className='font-koho'>₹ 0.20 <span>per hit</span></p>
                                                    <span className='font-koho'>Total URLs Created</span>
                                                    <h2 className="mb-0 font-koho font-secondary">{bill.total_urls}</h2>
                                                    <p className='font-koho'>₹ 0.60 <span>per url</span></p>
                                                    <h5 className={`f-w-600 f-16 font-koho ${bill.paidAt != null ? 'mb-0' : ''}`}>Paid Amount : <span style={{ color: parseInt(bill.paid_amount) > 0 ? 'green' : 'red' }}>₹ {bill.paid_amount}</span></h5>
                                                    <h6 className="f-w-600 f-12 font-koho" style={{ display: bill.paidAt != null ? '' : 'none' }}>{bill.paidAt != null ? new Date(parseInt(bill.paidAt)).toDateString() : ''}</h6>
                                                    <h5 className="f-w-600 f-16 font-koho mb-0">Total Hits : {bill.total_hits}</h5>
                                                </div>
                                                <div className="bg-primary b-r-8">
                                                    <div className="small-box bg-cust" style={{ borderRadius: 'inherit' }}>
                                                        <Repeat />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            <div>No billing generated Yet</div>
                        }

                    </div>
                </div>
            </Fragment>

        )
    }
}
// javascript:void(0)

export default ShortUrlService
