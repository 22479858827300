import React, { Component, Fragment } from "react";

import Breadcrumb from "./../common/breadcrumb";

import * as productApi from "./../../api/product";

import { checkUserTokenUtil } from "../../utils/utils";

export class SearchProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toSearch: ''
    };
  }

  componentDidMount = async () => {
    await checkUserTokenUtil();
  }

  searchParam = async (e) => {
    e.preventDefault();
    const data = {
      sku: this.state.toSearch
    }

    await productApi.findSingleProduct(data)
      .then(res => {
        console.log(res)
        if (res.status == 200) {
          window.open(process.env.PUBLIC_URL + '/products/product-detail/' + res.data)
        } else if (res.status == 405 && res.error == 'Product Does Not Exists') {
          alert('Product Does Not Exists')
        } else {
          alert('Something went wrong')
          window.location.reload();
        }
      })
      .catch((err) => {
        alert('Something went wrong')
        window.location.reload();
      })
  }

  resetSearch = e => {
    e.preventDefault();
    window.location.reload();
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Search Product" parent="Physical" />
        <div id="spinneranimid" className="overlay-spinner">
          <div className="cssload-whirlpool" />
        </div>
        <div className="container-fluid">

          <div className="card-header mb-3" >
            <h5 className="mb-2">Search Product By</h5>
            <p>( sku code )</p>
            <form onSubmit={this.searchParam}>
              <input style={{ maxWidth: '300px' }} className="form-control" id="searchParam" name="searchParam" onChange={(e) => this.setState({ toSearch: e.target.value })} required />

              <button type="submit" className="btn btn-primary mt-3">
                Search
              </button>

              <button type="btn" className="btn btn-primary mt-3 ml-3" onClick={this.resetSearch}>
                Reset
              </button>
            </form>

          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchProduct;