import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Pocket, Scissors, Trash2 } from "react-feather";
import StarRatingComponent from "react-star-rating-component";

import Breadcrumb from "./../common/breadcrumb";

import * as productApi from "./../../api/product";

import { toggleDeleteProductCart } from "./../../actions";

import { checkUserTokenUtil } from "../../utils/utils";

const findIndex = function (array, cb) {
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (true === cb(array[i]))
        return i;
    }
  }
  return -1;
}

export class DeleteProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: this.props.delete_cart,
    };
  }


  componentDidMount = async () => {
    await checkUserTokenUtil();
  }


  deleteProd = async (e, product) => {
    e.preventDefault();

    if (window.confirm('Delete the Product ?')) {

      const prodId = {
        id: product.id
      }

      await productApi.deleteProduct(prodId)
        .then(res => {
          if (res.status == 200) {
            alert('Product Deleted')
            this.props.toggleDeleteProductCart(product)
            window.location.reload();
          } else if (res.status == 405 && res.data.error == 'Product Does Not Exists') {
            alert('Product Does Not Exists')
            window.location.reload();
          } else {
            alert('Something went wrong')
            window.location.reload();
          }
        })
        .catch((err) => {
          alert('Something went wrong')
          window.location.reload();
        })

    }
  }

  addtoDeleteCart = (e, product) => {
    e.preventDefault();
    let spinner = document.getElementById("spinneranimid");
    spinner.style.display = "block";

    // var cart = this.props.delete_cart
    // let index2 = findIndex(cart, item => item.id == product.id);
    // if (-1 === index2) {
    //   cart.push(product);
    // } else {
    //   cart.splice(index2, 1);
    // }

    // this.setState({ products: cart }, () => {
    this.props.toggleDeleteProductCart(product)
    window.location.reload();
    //   spinner.style.display = "none";
    // })
  }

  deleteProducts = async e => {
    if (this.state.products.length) {
      if (window.confirm('Delete the Product ?')) {

        const data = {
          cart: this.state.products
        }

        await productApi.deleteMultipleProducts(data)
          .then(res => {
            if (res.status == 200) {
              alert('Product Deleted')
              for (var i = 0; i < this.state.products.length; i++) {
                this.props.toggleDeleteProductCart(this.state.products[i])
              }
              window.location.reload();
            } else {
              alert('Something went wrong')
              window.location.reload();
            }
          })
          .catch((err) => {
            alert('Something went wrong')
            window.location.reload();
          })
      }
    }
  }

  render() {
    const styles = {
      "margin-top": "15px"
    };
    const { symbol, delete_cart } = this.props;
    return (
      <Fragment>
        <Breadcrumb title="Delete Products" parent="Physical" />
        <div id="spinneranimid" className="overlay-spinner">
          <div className="cssload-whirlpool" />
        </div>
        <div className="container-fluid">

          <div className="card-header mb-3 pt-4 pb-4 text-center" >
            <button className="btn btn-secondary" type="button" onClick={this.deleteProducts}>
              Delete Multiple Products
            </button>
          </div>

          <div className="row products-admin ratio_asos">
            {this.state.products.map((products, index) => {
              return (
                <div className="col-xl-3 col-sm-6" key={index}>
                  <div className="card">
                    <div className="products-admin">
                      <Link to={`${process.env.PUBLIC_URL}/products/product-detail/${products.id}`}>
                        <div className="card-body product-box">
                          <div className="img-wrapper">

                            <div className="front">
                              <a className="bg-size">
                                <img
                                  className="img-fluid blur-up bg-img lazyloaded"
                                  src={`https://shivshahinetwork.com/${JSON.parse(products.pictures)[0]}`}
                                />
                              </a>
                              <div className="product-hover">
                                <ul>
                                  <li>
                                    <button className="btn fancybtn" type="button" onClick={e => this.deleteProd(e, products)}>
                                      <Scissors className="deleteBtn" />
                                    </button>
                                  </li>
                                  <li>
                                    <button className="btn fancybtn" type="button" onClick={e => this.addtoDeleteCart(e, products)}>
                                      <Trash2 className="deleteBtn" style={{ display: findIndex(delete_cart, item => item.id == products.id) == -1 ? '' : 'none' }} />
                                      <Pocket className="deleteBtn" style={{ display: findIndex(delete_cart, item => item.id == products.id) == -1 ? 'none' : '' }} />
                                    </button>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-detail" style={styles}>
                            <a className="pt-2">
                              {" "}
                              <h6>Sku: <span style={{ color: 'black' }}>{products.sku}</span></h6>
                            </a>
                            <div style={{ fontSize: 20, height: 31 }}>
                              <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                value={parseInt(products.ratings)}
                              />
                            </div>
                            <h4>
                              <strong>{symbol}{products.price}{" "}</strong>
                            </h4>
                            <a>
                              {" "}
                              <h6 style={{ color: 'black' }}><strong>{products.name}</strong></h6>
                            </a>

                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.data.products,
  symbol: state.data.symbol,
  cats: state.data.cats,
  delete_cart: state.filters.delete_cart
})

export default connect(mapStateToProps, { toggleDeleteProductCart })(DeleteProducts);