import React, { Component, Fragment } from 'react'
import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as orderApi from './../../api/order';

// const findIndex = function (array, cb) {
//     if (array) {
//         for (let i = 0; i < array.length; i++) {
//             if (true === cb(array[i]))
//                 return i;
//         }
//     }
//     return -1;
// }

export class ReturnExchangeRequests extends Component {

    constructor(props) {
        super(props)

        this.state = {
            requests: [],
            filtered_requests: [],
            requestStatus_filter: [],
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await orderApi.fetchReturnExchangeRequests()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ requests: (res.data).reverse(), filtered_requests: res.data })
                    } else {
                        alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    // onChangeStatusFilter = (e) => {

    //     let filterArrayRE = Object.assign([], this.state.requestStatus_filter);

    //     if (e.target.name == 'requestStatus') {
    //         let index = findIndex(filterArrayRE, request => request == e.target.value);
    //         if (-1 === index) {
    //             filterArrayRE.push(e.target.value);
    //         } else {
    //             filterArrayRE.splice(index, 1);
    //         }
    //     }

    //     this.setState({ requestStatus_filter: filterArrayRE }, () => {
    //         if (this.state.requestStatus_filter.length == 0) {
    //             this.setState({ filtered_requests: this.state.requests })
    //         } else {
    //             var filtered = this.state.requests.filter((item) => {
    //                 return this.state.requestStatus_filter.indexOf(item.request) !== -1;
    //             });
    //             this.setState({ filtered_requests: filtered })
    //         }
    //     })

    // }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Return & Exchange Requests" parent="Requests" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-3">Requests List</h5>
                                    <div className="filters-custom" id='SF'>
                                        {/* <div className='request-status-filter'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Exchange" id="Exchange" name='requestStatus' onChange={this.onChangeStatusFilter} />
                                                <label className="form-check-label" htmlFor="Exchange">
                                                    Exchange
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Return" id="Return" name='requestStatus' onChange={this.onChangeStatusFilter} />
                                                <label className="form-check-label" htmlFor="Return">
                                                    Return
                                                </label>
                                            </div>

                                        </div> */}
                                    </div>
                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.filtered_requests.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Request</th>
                                                    <th>Date</th>
                                                    <th>Order Id</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.filtered_requests.map((item, index) => {

                                                    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                                    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                                                    var date = new Date(item.date);
                                                    var dateFinal = days[date.getDay()] + ', ' + monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

                                                    return (
                                                        <tr key={index}>

                                                            <td>{index + 1}</td>
                                                            <td>{item.request}</td>
                                                            <td>{dateFinal}</td>
                                                            <td>{item.order_id}</td>
                                                            <td><strong>{item.status}</strong></td>
                                                            <td>
                                                                <a href={`${process.env.PUBLIC_URL}/requests/return-exchange-request-detail${item.id}`} target="_blank" rel="noopener noreferrer" className='aicon'><i className="fa fa-arrow-circle-o-right" /></a>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Request has been made Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default ReturnExchangeRequests
