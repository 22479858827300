import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as otherApi from './../../api/other';

export class ManageCatImages extends Component {

    constructor(props) {
        super(props)

        this.state = {
            catwall: [],
            cat: this.props.cats[0].category,
            image: null
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.fetchCatWall()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ catwall: res.data })
                    } else {
                        alert('Error Occured .. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured .. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    setImage = e => {
        let file = e.target.files[0];
        if (file != undefined) {
            if (
                file.type.toLowerCase() != 'image/jpg' &&
                file.type.toLowerCase() != 'image/jpeg' &&
                file.type.toLowerCase() != 'image/png'
            ) {
                this.setState({ image: null }, () => {
                    alert('Please upload a valid Image file..!')
                })
            } else {
                var _URL = window.URL || window.webkitURL;
                var img = new Image();
                var objectUrl = _URL.createObjectURL(file);
                const scope = this
                img.onload = function () {
                    if (this.width != 853 || this.height != 1280 || file.size > 512000) {
                        scope.setState({ image: null }, () => {
                            alert('Please make sure the image is 853px x 1280px and under 500 kb..!')
                        })
                    } else {
                        scope.setState({ image: file })
                    }
                    _URL.revokeObjectURL(objectUrl);
                };
                img.src = objectUrl;
            }
        } else {
            this.setState({ image: null })
        }
    }

    onAddCatWall = async (e) => {
        e.preventDefault();

        if (this.state.image == null) {
            alert('Please re-select the image ..!')
        } else {
            if (window.confirm('Do you want to add the Cat Wall ?')) {

                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                let dataToUpload = {
                    cat: this.state.cat
                }

                const formData = new FormData();
                formData.append('files', this.state.image)
                formData.append('dataToUplaod', JSON.stringify(dataToUpload))

                await otherApi.addCatWall(formData)
                    .then(res => {
                        if (res.status == 200) {
                            alert('Cat Wall Added ..!')
                            spinner.style.display = "none";
                            window.location.reload();
                        } else {
                            alert('Something went wrong')
                            spinner.style.display = "none";
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        alert('Something went wrong')
                        spinner.style.display = "none";
                        window.location.reload();
                    })

            }
        }

    }

    onDeleteCatWall = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Do you want to remove the Category Wall ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.deleteCatWall({ id: id })
                .then((res) => {
                    if (res.status == 200) {
                        alert("Cat Wall Deleted");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    onChangeCategory = async (e, id) => {

        if (window.confirm('Do you want to update the category ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            await otherApi.updateCatWallCategory({ id: id, cat: e.target.value })
                .then(res => {
                    if (res.status == 200) {
                        alert("Cat Wall Updated");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Category Wall" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className='mb-3'>Add Cat Wall</h5>
                                    <form onSubmit={this.onAddCatWall}>
                                        <label htmlFor='name'>Category* :</label>
                                        <select className="form-control" id="cat" name="cat" value={this.state.cat} onChange={(e) => this.setState({ cat: e.target.value })} style={{ maxWidth: '450px' }}>
                                            {this.props.cats.map((category, index) =>
                                                <option key={index}>{category.category}</option>
                                            )}
                                        </select>
                                        <label className='mt-1'>Select the Image*:</label>
                                        <input className="form-control" id="image" name="image" type="file" onChange={this.setImage} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Add Cat Wall
                                        </button>

                                    </form>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.catwall.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Category</th>
                                                    <th>Image</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.catwall.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <select className="form-control mt-3 mb-3" value={item.cat} onChange={e => this.onChangeCategory(e, item.id)} style={{ maxWidth: '250px' }}>
                                                                {this.props.cats.map((category, index) =>
                                                                    <option key={index}>{category.category}</option>
                                                                )}
                                                            </select>
                                                        </td>
                                                        <td className='pt-3 pb-3 pr-3 pl-3'><img style={{ width: '100px', borderRadius: '5%' }} src={`https://shivshahinetwork.com/assets/images/catwall/${item.image}`} alt={'image' + index} /></td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.onDeleteCatWall(e, item.id)} className='aicon'><i className="fa fa-close" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Cat Wall has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cats: state.data.cats,
    }
}

export default connect(mapStateToProps)(ManageCatImages);
