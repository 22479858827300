import axios from 'axios';

export const fetchBishiSchemes = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/fetchbishischemes`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const addBishiScheme = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/addbishischeme`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const editBishiSchemes = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishiRequests/editbishischeme`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteBishiScheme = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/deletebcscheme`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const toggleBishiScheme = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/togglebishischeme`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchBhishiRequests = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/fetchbhishirequests`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const approveOrRejectBhishiRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/approveorrejectbhishirequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};


export const deleteBhishiRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/bishirequests/deletebcrequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};
// export const acceptBishiReq = (data) => {
//     return axios
//         .post(`${process.env.PUBLIC_URL}/productrequest/addcategory`, data)
//         .then(res => { return res.data; })
//         .catch(err => { return err; })
// };