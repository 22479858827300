import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'

import logomonogram from './../../../assets/images/logo/android-chrome-512x512.png'

export class User_menu extends Component {

    logOut = e => {
        e.preventDefault()
        localStorage.clear();
        window.location = process.env.PUBLIC_URL + "/"
    }

    render() {
        return (
            <Fragment>
                <ul>
                    <li className="onhover-dropdown">
                        <div className="media align-items-center">
                            <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={logomonogram} alt="header-user" />
                            {/* <div className="dotted-animation"><span className="animate-circle"></span><span className="main-circle"></span></div> */}
                        </div>
                        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                            <li><Link to={`${process.env.PUBLIC_URL}/settings/profile`} ><i data-feather="user"></i>Profile</Link></li>
                            {/* <li><a href="javascript:void(0)"><i data-feather="mail"></i>Inbox</a></li>
                        <li><a href="javascript:void(0)"><i data-feather="lock"></i>Lock Screen</a></li>
                        <li><a href="javascript:void(0)"><i data-feather="settings"></i>Settings</a></li> */}
                            <li><a href="" onClick={this.logOut}>
                                Log Out
                            </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </Fragment>
        )
    }
}

export default withRouter(User_menu);
