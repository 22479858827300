import { Home, Box, DollarSign, User, Users, GitPullRequest, Share, Archive, Star } from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            { path: '/products/product-list', title: 'Product List', type: 'link' },
            { path: '/products/add-product', title: 'Add Product', type: 'link' },
            { path: '/products/delete-cart', title: 'Delete Products', type: 'link' },
            { path: '/products/search-product', title: 'Search Product', type: 'link' },
        ]
    },
    {
        title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/sales/orders', title: 'Orders', type: 'link' },
            { path: '/sales/transactions', title: 'Transactions', type: 'link' },
        ]
    },
    {
        title: 'Utilities', icon: Share, type: 'sub', active: true, children: [
            { path: '/utilities/manage-categories', title: 'Category', type: 'link' },
            { path: '/utilities/manage-colors', title: 'Color', type: 'link' },
            { path: '/utilities/manage-networks', title: 'Network', type: 'link' },
            { path: '/utilities/manage-specialtxns', title: 'Special Transaction', type: 'link' },
            { path: '/utilities/manage-trackings', title: 'Trackings', type: 'link' },
            { path: '/utilities/manage-links', title: 'Links', type: 'link' },
            // { path: '/utilities/manage-introsliders', title: 'Sliders', type: 'link' },
            { path: '/utilities/manage-clienttesimonials', title: 'Testimonials', type: 'link' },
            { path: '/utilities/manage-notifications', title: 'Notifications', type: 'link' },
            { path: '/utilities/manage-team-members', title: 'Team Members', type: 'link' },
            { path: '/utilities/manage-cat-images', title: 'Category Wall', type: 'link' },
        ]
    },
    {
        title: 'Bhishi', icon: Star, type: 'sub', active: true, children: [
            { path: '/bhishi/manage-bhishi', title: 'Manage Bhishi', type: 'link' },
            { path: '/bhishi/bhishi-requests', title: 'Bhishi Requests', type: 'link' },
        ]
    },
    {
        path: '/user-list', title: 'Users', icon: Users, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Requests', icon: GitPullRequest, type: 'sub', active: false, children: [
            { path: '/requests/recharge', title: 'Recharge', type: 'link' },
            { path: '/requests/withdrawl', title: 'Withdrawl', type: 'link' },
            { path: '/requests/returnexchange', title: 'Return & Exchange', type: 'link' },
        ]
    },
    {
        title: 'Service Dashboard', icon: Archive, type: 'sub', active: false, children: [
            { path: '/dashboard/sms-service', title: 'SMS Service', type: 'link' },
            { path: '/dashboard/short-url-service', title: 'Short URL Service', type: 'link' },
        ]
    },
    {
        path: '/settings/profile', title: 'Profile', icon: User, type: 'link', badgeType: 'primary', active: false
    },
]
