import React, { Component, Fragment } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, UserPlus } from "react-feather";
import { withRouter } from "react-router-dom";

import * as authApi from './../../api/auth'

export class LoginTabset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lemailorphone: '',
      lpassword: '',
      rname: '',
      remail: '',
      rphone: '',
      rpassword: '',
      rauthority: 'employee',
      risVerified: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitLogin = async (e) => {
    e.preventDefault();

    let spinner = document.getElementById("spinneranimid");
    spinner.style.display = "block";

    const loginData = {
      emailorphone: this.state.lemailorphone,
      password: this.state.lpassword,
    };

    await authApi.adminLogin(loginData)
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem("UTShivshahiAdmin", res.data);
          this.props.history.push(`${process.env.PUBLIC_URL}/products/product-list`)
        } else if (res.status == 405 && res.error == 'Password Wrong') {
          alert("Password is Incorrect")
          spinner.style.display = "none";
        } else if (res.status == 405 && res.error == 'Account Does Not Exists') {
          alert("Email or Phone is Incorrect")
          spinner.style.display = "none";
        } else if (res.status == 401 && res.error == 'Account Verification Pending') {
          alert("Account Verification Pending..!")
          spinner.style.display = "none";
        } else {
          alert("Something went wrong")
          spinner.style.display = "none";
        }
      })
      .catch((err) => {
        alert("Something went wrong")
        spinner.style.display = "none";
      })
  }

  onSubmitReg = async (e) => {
    e.preventDefault();

    let spinner = document.getElementById("spinneranimid");
    spinner.style.display = "block";

    const registerData = {
      name: this.state.rname,
      email: this.state.remail,
      phone: this.state.rphone,
      password: this.state.rpassword,
      authority: this.state.rauthority,
      isVerified: this.state.risVerified
    };

    await authApi.createAdminUser(registerData)
      .then(res => {
        if (res.status === 200) {
          alert('Your Account has been successfully Registered..! Wait for the admin to approve..!')
          window.location.reload();
        } else if (res.status == 405 && res.error == 'Email Already Exists') {
          alert("Email is already used..!")
          spinner.style.display = "none";
        } else if (res.status == 405 && res.error == 'Phone Already Exists') {
          alert("Phone is already used..!")
          spinner.style.display = "none";
        } else {
          alert("Something went wrong")
          spinner.style.display = "none";
        }
      })
      .catch((err) => {
        alert("Something went wrong")
        spinner.style.display = "none";
      })
  }

  render() {
    return (
      <div>
        <Fragment>
          <div id="spinneranimid" className="overlay-spinner">
            <div className="cssload-whirlpool" />
          </div>
          <Tabs>
            <TabList className="nav nav-tabs tab-coupon">
              <Tab className="nav-link" ><User />Login</Tab>
              <Tab className="nav-link" ><UserPlus />Register</Tab>
            </TabList>

            <TabPanel>
              <form className="form-horizontal auth-form" onSubmit={this.onSubmitLogin}>
                <div className="form-group">
                  <input required name="lemailorphone" type="email" className="form-control" placeholder="Email" id="lemailorphone" onChange={this.onChange} />
                </div>
                <div className="form-group">
                  <input required name="lpassword" type="password" className="form-control" placeholder="Password" id="lpassword" onChange={this.onChange} />
                </div>
                <div className="form-button">
                  <button className="btn btn-primary" type="submit">Login</button>
                </div>
              </form>
            </TabPanel>

            <TabPanel>
              <form className="form-horizontal auth-form" onSubmit={this.onSubmitReg}>
                <div className="form-group">
                  <input required name="rname" type="text" className="form-control" placeholder="Name" id="rname" onChange={this.onChange} />
                </div>
                <div className="form-group">
                  <input required name="remail" type="email" className="form-control" placeholder="Email" id="remail" onChange={this.onChange} />
                </div>
                <div className="form-group">
                  <input required name="rphone" type="number" className="form-control" placeholder="Phone" id="rphone" onChange={this.onChange} />
                </div>
                <div className="form-group">
                  <input required name="rpassword" type="password" className="form-control" placeholder="Password" id="rpassword" onChange={this.onChange} />
                </div>
                <div className="form-button">
                  <button className="btn btn-primary" type="submit">Register</button>
                </div>
              </form>
            </TabPanel>
          </Tabs>
        </Fragment>
      </div>
    );
  }
}

export default withRouter(LoginTabset);
