import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as userApi from './../../api/user';

export class WithdrawlRequests extends Component {

    constructor(props) {
        super(props)

        this.state = {
            requests: [],
            status: 'Requested'
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await userApi.fetchWithdrawlRequests({ status: this.state.status })
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ requests: res.data })
                    } else {
                        alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    changeSearch = e => {
        this.setState({ status: e.target.value }, async () => {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await userApi.fetchWithdrawlRequests({ status: this.state.status })
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ requests: res.data })
                    } else {
                        alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured While Fetching the Requests.. Please Try after some time')
                    spinner.style.display = "none";
                })
        })
    }

    onChangeStatus = async (e, id, status) => {
        e.preventDefault();

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        const request = {
            id: id,
            status: e.target.value,
        }

        if (e.target.value != status && e.target.value == 'Approved') {
            if (window.confirm('Do you want to update the status ?')) {
                await userApi.approveWithdrawlRequest(request)
                    .then((res) => {
                        if (res.status == 200) {
                            alert('Status Updated ..!')
                            window.location.reload();
                            spinner.style.display = "none";
                        } else {
                            alert('Something Went Wrong ..!')
                            window.location.reload();
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    })
            } else {
                spinner.style.display = "none";
            }
        } else {
            spinner.style.display = "none";
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Withdrawl Requests" parent="Requests" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <select className="form-control" id="searchparam" name="searchparam" value={this.state.status} onChange={this.changeSearch} style={{ maxWidth: '250px' }}>
                                        <option value="Requested">Requested</option>
                                        <option value="Approved">Approved</option>
                                    </select>
                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.requests.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Date</th>
                                                    <th>Bank Details</th>
                                                    <th>Requested Amount</th>
                                                    <th>Eligible Amount</th>
                                                    <th>Status</th>
                                                    <th>Update Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.requests.map((item, index) => {

                                                    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                                    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                                                    var date = new Date(item.date);
                                                    var dateFinal = days[date.getDay()] + ', ' + monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();

                                                    return (
                                                        <tr key={index}>

                                                            <td>{index + 1}</td>
                                                            <td>{JSON.parse(item.user).firstname}&nbsp;{JSON.parse(item.user).lastname}</td>
                                                            <td>{JSON.parse(item.details).phone}</td>
                                                            <td>{dateFinal}</td>
                                                            <td style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', textAlign: 'start' }}>
                                                                Account&nbsp;No.:&nbsp;&nbsp;<strong>{JSON.parse(item.details).accountNumber}</strong> <br />
                                                                IFSC&nbsp;Code:&nbsp;&nbsp;<strong>{JSON.parse(item.details).ifscCode}</strong> <br />
                                                                Account&nbsp;Holder:&nbsp;&nbsp;<strong>{JSON.parse(item.details).accountName}</strong> <br />
                                                                Account&nbsp;Type:&nbsp;&nbsp;<strong>{JSON.parse(item.details).accountType}</strong> <br />
                                                                Bank:&nbsp;&nbsp;<strong>{JSON.parse(item.details).bankName}</strong> <br />
                                                                Branch:&nbsp;&nbsp;<strong>{JSON.parse(item.details).branchName}</strong>
                                                            </td>
                                                            <td>₹&nbsp;{item.requested_amount}</td>
                                                            <td><strong><span
                                                                style={{
                                                                    color: 'black',
                                                                    backgroundColor: '#e2e3e5',
                                                                    padding: '8px 8px 8px 8px'
                                                                }}
                                                            >₹&nbsp;{item.allowed_amount}</span></strong></td>
                                                            <td style={{ color: item.status == "Approved" ? 'green' : item.status == "Requested" ? '' : 'red' }}><strong>{item.status}</strong></td>
                                                            <td>
                                                                <select className="form-control" id="statuschange" name="status" value={item.status} onChange={(e) => this.onChangeStatus(e, item.id, item.status)} style={{ minWidth: '150px' }}>
                                                                    <option value="Requested">Requested</option>
                                                                    <option value="Approved">Approved</option>
                                                                </select>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Request has been made Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default WithdrawlRequests
