import React, { Component, Fragment } from 'react'
import { LogOut } from 'react-feather';

import TabsetProfile from './tabset-profile';
import Breadcrumb from './../common/breadcrumb';
import { checkUserTokenUtil } from '../../utils/utils';

export class Profile extends Component {

    componentDidMount = async () => {
        await checkUserTokenUtil();
    }

    logOut = e => {
        e.preventDefault()
        localStorage.clear();
        window.location = process.env.PUBLIC_URL + "/"
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Profile" parent="Settings" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <TabsetProfile />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <button type="submit" className="btn btn-primary" onClick={this.logOut}>
                                Logout&nbsp;&nbsp;<LogOut size="15px" />
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Profile
