import {
  FETCH_SINGLE_PRODUCT,
  CHANGE_CURRENCY,
  RECEIVE_PRODUCTS,
  RECEIVE_CATS,
  RECEIVE_FILTERCLRS,
  RECEIVE_TRACKINGS
} from "../constants/ActionTypes";

const initialState = {
  products: [],
  symbol: "₹",
  product_details: [],
  cats: [],
  filterclrs: [],
  trackings: [],
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.products };
    case RECEIVE_CATS:
      return { ...state, cats: action.cats };
    case RECEIVE_FILTERCLRS:
      return { ...state, filterclrs: action.filterclrs };
    case RECEIVE_TRACKINGS:
      return { ...state, trackings: action.trackings };
    case FETCH_SINGLE_PRODUCT:
      if (
        state.products.findIndex(
          (product) => product.id === action.productId
        ) !== -1
      ) {
        const singleItem = state.products.reduce((itemAcc, product) => {
          return product;
        }, []);
        return { ...state, product_details: singleItem };
      }

    case CHANGE_CURRENCY:
      return { ...state, symbol: action.symbol };
    default:
      return state;
  }
};
export default productReducer;
