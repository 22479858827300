// import React, { Component, Fragment } from 'react'
// import { connect } from 'react-redux';

// import { checkUserTokenUtil } from '../../utils/utils';

// import Breadcrumb from '../common/breadcrumb';

// import * as bishiApi from '../../api/bishi';

// export class BhishiRequests extends Component {

//     constructor(props) {
//         super(props)

//         this.state = {
//             bishiRequests: [],
//             isApproved: '',
//         }
//     }

//     componentDidMount = async () => {
//         await this.fetchBishiRequests();
//         await checkUserTokenUtil();
//     }

//     fetchBishiRequests = async () => {
//         let spinner = document.getElementById("spinneranimid");
//         spinner.style.display = "block";

//         await bishiApi.fetchBhishiRequests()
//             .then(res => {
//                 console.log(res)
//                 if (res.status == 200) {
//                     this.setState({
//                         bishiRequests: res.data,
//                     })
//                     spinner.style.display = "none";
//                 } else {
//                     alert('Error Occured While fetching the bhihsi requests.. Please Try after some time')
//                     spinner.style.display = "none";
//                 }
//             })
//             .catch((err) => {
//                 alert('Error Occured While fetching the bhihsi requests.. Please Try after some time')
//                 spinner.style.display = "none";
//             })
//     }

//     approveOrRejectBhishiRequest = async (e, id, isApproved) => {

//         e.preventDefault();

//         if (window.confirm('Approve or Reject Bihsi request?')) {

//             let spinner = document.getElementById("spinneranimid");
//             spinner.style.display = "block";

//             const data = {
//                 id: id,
//                 isApproved: !isApproved
//             }

//             await bishiApi.approveOrRejectBhishiRequest(data)
//                 .then(res => {
//                     if (res.status == 200) {
//                         alert('Bihsi scheme updated ..! Please refresh the page after fully loaded..')
//                         window.location.reload();
//                         spinner.style.display = "none";
//                     } else if (res.status == 405 && res.error == 'Bihsi scheme Does Not Exists') {
//                         alert('This Bihsi scheme does not exists..!')
//                         window.location.reload();
//                         spinner.style.display = "none";
//                     } else {
//                         alert('Error Occured While Updating the Bihsi scheme.. Please Try after some time')
//                         window.location.reload();
//                         spinner.style.display = "none";
//                     }
//                 })
//                 .catch((err) => {
//                     alert('Error Occured While Updating the Bihsi scheme.. Please Try after some time')
//                     window.location.reload();
//                     spinner.style.display = "none";
//                 })
//         }
//     }


//     render() {
//         return (
//             <Fragment>
//                 <Breadcrumb title="Bihsi Requests" parent="Bishi" />

//                 <div id="spinneranimid" className="overlay-spinner">
//                     <div className="cssload-whirlpool" />
//                 </div>

//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-sm-12">
//                             <div className="card">
//                                 <div className="card-header">

//                                     <hr />

//                                 </div>
//                                 <div className="card-body order-datatable lists-custom">

//                                     {this.state.bishiRequests.length > 0 ?
//                                         <table>
//                                             <thead>
//                                                 <tr>
//                                                     <th>Sr. No.</th>
//                                                     <th>Customer Name</th>
//                                                     <th>Customer Phone</th>
//                                                     <th>Customer City</th>
//                                                     <th>Bishi Scheme Id</th>
//                                                     <th>Bishi Scheme Name</th>
//                                                     <th>Approve</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {this.state.bishiRequests.map((item, index) =>
//                                                     <tr key={index}>
//                                                         <td>{index + 1}</td>
//                                                         <td>{item.name}</td>
//                                                         <td>{item.phone}</td>
//                                                         <td>{item.city}</td>
//                                                         <td>{item.bcSchemeId}</td>
//                                                         <td>{item.SchemeName}</td>
//                                                         <td>
//                                                             <a href='#' onClick={(e) => this.approveOrRejectBhishiRequest(e, item.id, item.isApproved)} className='aicon'><i className="fa fa-times" /></a>
//                                                         </td>
//                                                         <td>
//                                                             <a href='#' onClick={(e) => this.approveOrRejectBhishiRequest(e, item.id, item.isApproved)} className='aicon'><i className="fa fa-times" /></a>
//                                                         </td>
//                                                     </tr>
//                                                 )}
//                                             </tbody>
//                                         </table>

//                                         :
//                                         <div>
//                                             <p>No Bishi request has been requested yet.</p>
//                                         </div>
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             </Fragment >
//         )

//     }
// }

// const mapStateToProps = (state) => ({
//     bishiRequests: state.data.bishiRequests,
// })

// export default connect(mapStateToProps)(BhishiRequests);

import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import { connect } from 'react-redux';

import Breadcrumb from '../common/breadcrumb';

import * as bishiApi from '../../api/bishi';

export class BhishiRequests extends Component {

    constructor(props) {
        super(props)

        this.state = {
            bishiRequests: [],
            status: '',
            searchParam: 'All'
        }
    }

    componentDidMount = async () => {
        await this.fetchBishiRequests();
        await checkUserTokenUtil();
    }

    fetchBishiRequests = async () => {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        await bishiApi.fetchBhishiRequests({ status: this.state.searchParam })
            .then(res => {
                console.log(res)
                if (res.status == 200) {
                    this.setState({
                        bishiRequests: res.data,
                    })
                    spinner.style.display = "none";
                } else {
                    alert('Error Occured While fetching the bhihsi requests.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured While fetching the bhihsi requests.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    approveOrRejectBhishiRequest = async (e, id, isApproved) => {

        e.preventDefault();

        if (window.confirm('Approve Bihsi request?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            const data = {
                id: id
            }
            if (isApproved == 1 && e.target.value == 'Approved')
                return;
            if (isApproved == 0 && e.target.value == 'Requested')
                return;
            if (e.target.value == 'Approved') {
                data['isApproved'] = 1;
            }
            else if (e.target.value == 'Requested')
                data['isApproved'] = 0;

            console.log(data, id)
            await bishiApi.approveOrRejectBhishiRequest(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Bihsi scheme updated ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Bihsi scheme Does Not Exists') {
                        alert('This Bihsi scheme does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Updating the Bihsi scheme.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Updating the Bihsi scheme.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteBhishiRequest = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Delete Bhishi Request?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: id
            }

            await bishiApi.deleteBhishiRequest(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Bhishi request Deleted ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Bhishi request Does Not Exists') {
                        alert('This Color does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the Bhishi request.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the Bhishi request.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    changeSearch = (e) => {
        this.setState({ searchParam: e.target.value }, async () => {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await this.fetchBishiRequests();
        })
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Bhishi Requests" parent="Requests" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <select className="form-control" id="searchparam" name="searchparam" value={this.state.isApproved} onChange={(e) => this.changeSearch(e)} style={{ maxWidth: '250px' }}>
                                        <option value="All">All</option>
                                        <option value="Requested">Requested</option>
                                        <option value="Approved">Approved</option>
                                    </select>
                                </div>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.bishiRequests.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Bhishi Scheme Name</th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>City</th>
                                                    <th>Status</th>
                                                    <th>Update Status</th>
                                                    <th>Delete Request</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.bishiRequests.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.SchemeName}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.phone}</td>
                                                            <td>{item.city}</td>
                                                            <td style={{ color: item.Approved == 1 ? 'green' : 'red' }}><strong>{item.Approved == 1 ? 'Approved' : 'Requested'}</strong></td>
                                                            <td>
                                                                <select className="form-control" id="statuschange" name="status" value={item.Approved == 1 ? 'Approved' : 'Requested'} onChange={(e) => this.approveOrRejectBhishiRequest(e, item.id, item.Approved)} style={{ minWidth: '150px' }}>
                                                                    <option value="Requested">Requested</option>
                                                                    <option value="Approved">Approved</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <a href='#' onClick={(e) => this.deleteBhishiRequest(e, item.id)} className='aicon'><i className="fa fa-times" /></a>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Request has been made Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

const mapStateToProps = (state) => ({
    bishiRequests: state.data.bishiRequests,
})

export default connect(mapStateToProps)(BhishiRequests)
