import axios from 'axios';

export const addTracking = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/addtracking`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const deleteTracking = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/deletetracking`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchReturnExchangeRequests = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchreturnexchangerequests`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchSingleReturnExchangeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchsinglereturnexchangerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateReturnExchangeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updatereturnexchangerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchTransactions = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchtransactions`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchOrders = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchorders`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchVendors = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchvendors`)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchSingleOrder = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/fetchsingleorder`, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
};

export const updatePaymentStatus = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updatepaymentstatus`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateOrderStatus = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateorderstatus`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateOrderTracking = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateordertracking`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const distributeProfit = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/distributeprofit`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};


export const updateItemTracking = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateitemtracking`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateItemStatus = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateitemstatus`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateItemVendor = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateitemvendor`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateItemCourierService = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/orderrequest/updateItemCourierService`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};