import jwt_decode from 'jwt-decode';

/**
 * find index of first matched string
 */
export const findIndex = function (array, cb) {
    if (array) {
        for (let i = 0; i < array.length; i++) {
            if (true === cb(array[i]))
                return i;
        }
    }
    return -1;
}

export const checkUserTokenUtil = () => {
    var checkUserToken = localStorage.getItem('UTShivshahiAdmin');

    if (window.location.pathname == '/') {
        if (checkUserToken != null && checkUserToken != undefined) {
            window.location = process.env.PUBLIC_URL + "/products/product-list";
        }
        return null;
    } else {
        if (checkUserToken == null || checkUserToken == undefined) {
            window.location = process.env.PUBLIC_URL + '/'
            return null;
        } else {
            const decoded = jwt_decode(checkUserToken).data
            if (parseInt(decoded.id) != 2 && parseInt(decoded.id) < 31) {
                alert('You are not authorised..!')
                localStorage.clear();
                window.location = process.env.PUBLIC_URL + '/'
                return null;
            } else {
                return decoded;
            }
        }
    }
}