import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';
import { toggleOrderStatusFilter } from './../../actions';

import * as orderApi from './../../api/order'

const findIndex = function (array, cb) {
    if (array) {
        for (let i = 0; i < array.length; i++) {
            if (true === cb(array[i]))
                return i;
        }
    }
    return -1;
}

export class Orders extends Component {

    constructor(props) {
        super(props)

        this.state = {
            orders: [],
            orderItems: [],
            toSearch: 0,
            orderStatus_filter: [],
            // orderStatus_filter: [{ status: 'Placed' }, { status: 'Processed' }, { status: 'Packed' }, { status: 'Shipped' }, { status: 'Delivered' }, { status: 'Exchange in Progress' }, { status: 'Return in Progress' },],
            paymentStatus_filter: [],
            pages: 0,
            pagesArray: [],
            currentPage: 1,
            offset: 0,
            limit: 50,
            isRange: false,
            date: "",
            fromDate: "",
            toDate: "",
            filteredOrders: [],
            totalOrders: 0,
            totalAmount: 0,
            totalQuantity: 0
        }
    }

    callFetchOrders = async (e) => {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";
        var data = {
            offset: this.state.offset,
            limit: this.state.limit,
            searchString: this.state.toSearch,
            filterArrayOS: this.state.orderStatus_filter,
            filterArrayPS: this.state.paymentStatus_filter,
            date: this.state.date,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate
        }
        await orderApi.fetchOrders(data)
            .then(res => {
                if (res.status == 200) {
                    this.state.totalOrders = res.data.ordersTotalCount
                    this.state.totalAmount = res.data.ordersTotalAmount
                    this.state.totalQuantity = res.data.ordersTotalQuantity

                    this.setState({ orders: res.data.orders, orderItems: res.data.orderItems, pages: res.data.ordersTotalCount % this.state.limit == 0 ? res.data.ordersTotalCount / this.state.limit : parseInt(res.data.ordersTotalCount / this.state.limit) + 1 }, () => {
                        var pagesArray = [];
                        for (var i = 0; i < this.state.pages; i++) {
                            pagesArray.push(i + 1)
                        }
                        this.state.orders.map((item, index) => {
                            var totalQty = 0;
                            JSON.parse(item.items).map((qty, index2) => {
                                totalQty = parseInt(totalQty) + parseInt(qty.qty)
                            })
                            this.state.orders[index].totalQty = totalQty
                        })
                        this.setState({ pagesArray: pagesArray }, () => {
                            setTimeout(() => {
                                spinner.style.display = "none";
                            }, 500);
                        })
                    })
                } else {
                    alert('Error Occured.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            await this.callFetchOrders();
        }
    }

    onChangeStatusFilter = (e) => {

        let filterArrayOS = Object.assign([], this.state.orderStatus_filter);
        let filterArrayPS = Object.assign([], this.state.paymentStatus_filter);

        if (e.target.name == 'orderStatus') {
            let index = findIndex(filterArrayOS, item => item.status == e.target.value);
            if (-1 === index) {
                filterArrayOS.push({ status: e.target.value });
            } else {
                filterArrayOS.splice(index, 1);
            }
        } else if (e.target.name == 'paymentStatus') {
            let index2 = findIndex(filterArrayPS, item => item.payment_status == e.target.value);
            if (-1 === index2) {
                filterArrayPS.push({ payment_status: e.target.value });
            } else {
                filterArrayPS.splice(index2, 1);
            }
        }

        this.setState({ orderStatus_filter: filterArrayOS, paymentStatus_filter: filterArrayPS, currentPage: 1, offset: 0, limit: 50, toSearch: 0 }, () => {
            this.callFetchOrders();
        })

    }

    searchParam = (e) => {
        e.preventDefault();
        this.setState({ currentPage: 1, offset: 0, limit: 50 }, () => {
            this.callFetchOrders();
        })
    }

    resetSearch = (e) => {
        e.preventDefault();
        window.location.reload();
    }

    changePage = (e) => {
        var offset = this.state.limit * (e.target.value - 1)
        this.setState({ currentPage: e.target.value, offset: offset }, () => {
            this.callFetchOrders();
        })
    }

    onChangeStatus = async (e, order) => {

        let spinner = document.getElementById("spinneranimid");

        if (e.target.name == 'payment_status_select') {
            if (window.confirm(`Update the payment status ?`)) {
                spinner.style.display = "block";
                var newModifiedProducts = [];

                JSON.parse(order.items).map((item, index) => {
                    newModifiedProducts.push({ id: item.id, qty: parseInt(item.qty) })
                })
                var dataPS = {
                    stockData: newModifiedProducts,
                    id: order.id,
                    display_order_id: parseInt(order.id) + 9999,
                    payment_status: e.target.value,
                    isOkToUpdateStock: false
                }

                if (window.confirm(`Do you want to ${e.target.value == 'Completed' ? 'reduce' : 'increase'} the Stock ?`)) {
                    dataPS.isOkToUpdateStock = true
                } else {
                    dataPS.isOkToUpdateStock = false
                }
                await orderApi.updatePaymentStatus(dataPS)
                    .then((res) => {
                        if (res.status == 200) {
                            alert('Payment Status Updated..!')
                            spinner.style.display = "none";
                            window.location.reload()
                        } else {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    })
            }
        } else {
            if (order.status != 'Cancelled') {

                var result = this.state.orderItems.filter(obj => {
                    return obj.id === order.id
                })

                var bFlag = false
                for (var i = 0; i < result.length; i++) {
                    if (result[i].orderItemStatus == 'Cancelled') {
                        bFlag = true
                        break
                    }
                }

                if (bFlag) {
                    alert("Not able to cancel bulk order, please cancel individual order.")
                } else {

                    if (window.confirm(`Update the order status ?`)) {
                        spinner.style.display = "block";
                        var dataOS = {
                            id: order.id,
                            display_order_id: parseInt(order.id) + 9999,
                            status: e.target.value
                        }
                        await orderApi.updateOrderStatus(dataOS)
                            .then((res) => {
                                if (res.status == 200) {
                                    alert('Status Updated ..!')
                                    window.location.reload()
                                    spinner.style.display = "none";
                                } else if (res.status == 402) {
                                    if (res.error == 'status is cancelled') {
                                        alert('Not allowed to change status after cancellation..!')
                                    } else if (res.error == 'already distributed') {
                                        alert('After Distribution, order is not allowed to be Cancelled..!')
                                    } else if (res.error == 'payment needed') {
                                        alert('Payment should be completed first..!')
                                    }
                                    spinner.style.display = "none";
                                } else {
                                    alert('Something Went Wrong ..!')
                                    window.location.reload()
                                    spinner.style.display = "none";
                                }
                            })
                            .catch((err) => {
                                alert('Something Went Wrong ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            })
                    }
                }
            } else {
                alert('Not allowed to change status after cancellation..!')
            }
        }

    }

    distributeProfit = async (e, order) => {
        if (e.target.value == 'true') {
            if (order.payment_status == 'Completed' && (order.status == 'Shipped' || order.status == 'Delivered')) {
                if (window.confirm('Do you want to Distribute the profits ?')) {

                    let spinner = document.getElementById("spinneranimid");
                    spinner.style.display = "block";

                    const data = {
                        id: order.id,
                        display_order_id: parseInt(order.id) + 9999,
                        dataToUpdate: { isDistributed: true }
                    }
                    await orderApi.distributeProfit(data)
                        .then((res) => {
                            if (res.status == 200) {
                                alert('Profits Distributed ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            } else {
                                alert('Something Went Wrong ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            }
                        })
                        .catch((err) => {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        })

                }
            } else {
                alert('Order must be Shipped and Payment should be completed..!')
            }
        } else {
            alert('You can not revert profit distribution changes when already done')
        }
    }

    viewImages = (e, payment_receipts) => {
        if (payment_receipts != null) {
            JSON.parse(payment_receipts).map((image, index) => {
                window.open(`https://shivshahinetwork.com/assets/images/order-payment-receipts/${image}`);
            })
        } else {
            alert('There are no Old receipts uploaded')
        }
    }

    filterOrderByDate = (orders) => {
        if (this.state.isRange) {
            if (this.state.fromDate !== "" && this.state.toDate !== "") {
                this.state.filteredOrders = []
                this.state.filteredOrders = orders.filter((item) => {
                    var from = new Date(this.state.fromDate);
                    var to = new Date(this.state.toDate);
                    var dateToCheck = new Date(item.order_date)
                    if (dateToCheck.getTime() <= to.getTime() && dateToCheck.getTime() >= from.getTime()) {
                        return item;
                    }
                })
            } else
                this.state.filteredOrders = orders
        } else {
            if (this.state.date !== "") {
                this.state.filteredOrders = []
                this.state.filteredOrders = orders.filter((item) => {
                    var date = new Date(this.state.date);
                    var dateToCheck = new Date(item.order_date)
                    if (dateToCheck.getFullYear() === date.getFullYear() ?
                        (dateToCheck.getMonth() === date.getMonth() ?
                            (dateToCheck.getDate() === date.getDate() ?
                                true : false)
                            : false)
                        : false) {
                        console.log(item.order_date);
                        console.log(this.state.date);
                        return item;
                    }
                })
            } else
                this.state.filteredOrders = orders
        }
        console.log(this.state.filteredOrders);
        return this.state.filteredOrders;
    }

    render() {
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

        return (
            <Fragment>
                <Breadcrumb title="Orders" parent="Sales" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-2">Search Order By</h5>
                                    <form onSubmit={this.searchParam}>
                                        <div className="search-input-boxes">
                                            <div className='inner-div'>
                                                <p>Order Id</p>
                                                <input style={{ maxWidth: '200px' }} type='number' className="form-control" id="searchParam" name="searchParam" onChange={(e) => this.setState({ toSearch: e.target.value })} />
                                            </div>
                                            <div className='inner-div'>
                                                {this.state.isRange ?
                                                    <div className='row-div'>
                                                        <div>
                                                            <p>From</p>
                                                            <input style={{ maxWidth: '200px' }} type='date' className="form-control" onChange={(e) => this.setState({ fromDate: e.target.value })} />
                                                        </div>
                                                        <div>
                                                            <p>To</p>
                                                            <input style={{ maxWidth: '200px' }} type='date' className="form-control" onChange={(e) => this.setState({ toDate: e.target.value })} />
                                                        </div>
                                                    </div> :
                                                    <div>
                                                        <p>Date</p>
                                                        <input style={{ maxWidth: '200px' }} type='date' className="form-control" onChange={(e) => this.setState({ date: e.target.value })} />
                                                    </div>
                                                }
                                                <div className='radio-button-group'>
                                                    <input type="radio" id="date" value="date" checked={!this.state.isRange} onChange={(e) => {
                                                        this.setState({ fromDate: '' })
                                                        this.setState({ toDate: '' })
                                                        this.setState({ date: '' })
                                                        this.setState({ isRange: false })
                                                    }}></input>
                                                    <label >Date</label>
                                                    <input type="radio" id="range" value="range" checked={this.state.isRange} onChange={(e) => {
                                                        this.setState({ fromDate: '' })
                                                        this.setState({ toDate: '' })
                                                        this.setState({ date: '' })
                                                        this.setState({ isRange: true })
                                                    }}></input>
                                                    <label >Range</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="search-buttons">
                                            <button type="submit" className="btn btn-primary mt-3">Search</button>
                                            <button type="btn" className="btn btn-primary mt-3 ml-3" onClick={this.resetSearch}>Reset</button>
                                        </div>
                                    </form>
                                    <hr />
                                    <h5 className="mb-3">Filter Orders</h5>
                                    <div className="filters-custom" id='SF'>
                                        <div className='order-status-filter'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Placed" id="Placed" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Placed') != -1} />
                                                <label className="form-check-label" htmlFor="Placed">
                                                    Placed
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Processed" id="Processed" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Processed') != -1} />
                                                <label className="form-check-label" htmlFor="Processed">
                                                    Processed
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Packed" id="Packed" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Packed') != -1} />
                                                <label className="form-check-label" htmlFor="Packed">
                                                    Packed
                                                </label>
                                            </div> */}
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Shipped" id="Shipped" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Shipped') != -1} />
                                                <label className="form-check-label" htmlFor="Shipped">
                                                    Shipped
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Delivered" id="Delivered" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Delivered') != -1} />
                                                <label className="form-check-label" htmlFor="Delivered">
                                                    Delivered
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Exchange in Progress" id="Exchange in Progress" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Exchange in Progress') != -1} />
                                                <label className="form-check-label" htmlFor="Exchange in Progress">
                                                    Exchange in Progress
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Return in Progress" id="Return in Progress" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Return in Progress') != -1} />
                                                <label className="form-check-label" htmlFor="Return in Progress">
                                                    Return in Progress
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Cancelled" id="Cancelled" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'Cancelled') != -1} />
                                                <label className="form-check-label" htmlFor="Cancelled">
                                                    Cancelled
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="On Hold" id="On Hold" name='orderStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.orderStatus_filter, item => item.status == 'On Hold') != -1} />
                                                <label className="form-check-label" htmlFor="On Hold">
                                                    On Hold
                                                </label>
                                            </div>
                                        </div>
                                        <div className='payment-status-filter'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Completed" id="Completed" name='paymentStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.paymentStatus_filter, item => item.payment_status == 'Completed') != -1} />
                                                <label className="form-check-label" htmlFor="Completed">
                                                    Completed
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="Pending" id="Pending" name='paymentStatus' onChange={this.onChangeStatusFilter} checked={findIndex(this.state.paymentStatus_filter, item => item.payment_status == 'Pending') != -1} />
                                                <label className="form-check-label" htmlFor="Pending">
                                                    Pending
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.orders.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Total Orders</th>
                                                    <th>Total Orders Amount</th>
                                                    <th>Total Orders Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {<tr>
                                                    <td>
                                                        {
                                                            this.state.totalOrders
                                                        }
                                                    </td>
                                                    <td><strong>
                                                        ₹&nbsp;{this.state.totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </strong>
                                                    </td>
                                                    <td>{
                                                        this.state.totalQuantity
                                                    }</td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <div>
                                            <p>No order has been made yet.</p>
                                        </div>
                                    }
                                </div>
                                <hr></hr>
                                <div className="card-body order-datatable lists-custom">
                                    {this.state.orders.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Order ID</th>
                                                    <th>Order Date</th>
                                                    <th>Address</th>
                                                    <th>Total</th>
                                                    <th>Total Qty</th>
                                                    <th>Payment Mode</th>
                                                    <th>Payment Status</th>
                                                    <th>Order Status</th>
                                                    <th>Profit Distribution</th>
                                                    <th>Tracking ID</th>
                                                    <th>Courier Service</th>
                                                    <th>Order Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders.map((item, index) =>
                                                    <tr key={index}>

                                                        <td>{index + 1}</td>
                                                        <td>{parseInt(item.id) + 9999}</td>
                                                        <td>{days[new Date(item.order_date).getDay()] + ', ' + monthNames[new Date(item.order_date).getMonth()] + ' ' + new Date(item.order_date).getDate() + ', ' + new Date(item.order_date).getFullYear()}</td>
                                                        <td>
                                                            <p>
                                                                {item.firstname}&nbsp;{item.lastname}, <br />
                                                                {item.address},<br />
                                                                {/* {item.city},&nbsp;{item.district} <br />
                                                                {item.state},&nbsp;{item.pincode}. <br /> */}
                                                                {item.pincode}.<br />
                                                                {item.phone}<br />
                                                                {item.email}<br />
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <strong>
                                                                ₹&nbsp;{item.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </strong>
                                                        </td>
                                                        <td>{item.totalQty}</td>
                                                        <td>{item.payment_mode}</td>
                                                        <td style={{ color: item.payment_status == 'Completed' ? 'green' : 'red' }}>
                                                            <strong>{item.payment_status}</strong>
                                                            <select className="form-control mt-2" id="payment_status_select" name="payment_status_select" value={item.payment_status} onChange={(e) => this.onChangeStatus(e, item)} style={{ minWidth: '150px', display: item.isDistributed || item.status == 'Return in Progress' || item.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                                <option value="Completed">Completed</option>
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                            <div style={{ fontSize: '13px', color: '#40a0a3', cursor: 'pointer', marginTop: '10px' }} onClick={e => this.viewImages(e, item.payment_receipts)}>View Receipts</div>
                                                        </td>
                                                        <td><strong><span
                                                            style={{
                                                                color: item.status == 'Placed' ? '#255d9a' : item.status == 'Processed' ? '#7300b0' : item.status == 'Packed' ? '#aa8f40' : item.status == 'Shipped' ? '#7bab00' : item.status == 'Delivered' ? '#137d00' : item.status == 'Exchange in Progress' ? '#fff' : item.status == 'Return in Progress' ? '#fff' : item.status == 'On Hold' ? '#ff2121' : '#3d4246',
                                                                backgroundColor: item.status == 'Placed' ? '#cce5ff' : item.status == 'Processed' ? '#e4b6fc' : item.status == 'Packed' ? '#fff3cd' : item.status == 'Shipped' ? '#deff8a' : item.status == 'Delivered' ? '#7bff63' : item.status == 'Exchange in Progress' ? '#dba100' : item.status == 'Return in Progress' ? '#ff6666' : item.status == 'On Hold' ? '#ff9191' : '#e2e3e5',
                                                                padding: '8px 8px 8px 8px'
                                                            }}
                                                        >{item.status == 'Exchange in Progress' ? 'Exchange' : item.status == 'Return in Progress' ? 'Return' : item.status}</span></strong>
                                                            <select className="form-control mt-2" id="order_status_select" name="order_status_select" value={item.status} onChange={(e) => this.onChangeStatus(e, item)} style={{ minWidth: '150px', display: item.status == 'Return in Progress' || item.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                                <option value="Placed">Placed</option>
                                                                <option value="Processed">Processed</option>
                                                                {/* <option value="Packed">Packed</option> */}
                                                                <option value="Shipped">Shipped</option>
                                                                <option value="Delivered">Delivered</option>
                                                                { }
                                                                <option value="Cancelled">Cancelled</option>
                                                                <option value="On Hold">On Hold</option>
                                                            </select>
                                                        </td>

                                                        <td>{item.isDistributed ? 'Distributed' : 'Pending'}
                                                            <select className="form-control" id="distribution_select" name="distribution_select" value={item.isDistributed} onChange={(e) => this.distributeProfit(e, item)} style={{ minWidth: '150px', display: item.isDistributed || item.status == 'Return in Progress' || item.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                                <option value='false'>Pending</option>
                                                                <option value="true">Distributed</option>
                                                            </select>
                                                        </td>

                                                        <td>{item.tracking_id}</td>
                                                        <td>
                                                            {this.props.trackings.map((trackinglist, index) =>
                                                                <a key={index} href={trackinglist.link} target="_blank" rel="noopener noreferrer" style={{ display: item.service == trackinglist.service ? '' : 'none', fontSize: '15px', color: '#c96' }}>{trackinglist.service}</a>
                                                            )}

                                                        </td>
                                                        <td>
                                                            <a href={`${process.env.PUBLIC_URL}/sales/order-detail${item.id}`} target="_blank" rel="noopener noreferrer" className='aicon'><i className="fa fa-arrow-circle-o-right" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No order has been made yet.</p>
                                        </div>
                                    }
                                    <hr />
                                    <select className="form-control mt-3 mb-3" id="pages" name="pages" value={this.state.currentPage} onChange={this.changePage} style={{ maxWidth: '250px' }}>
                                        {this.state.pagesArray.map((item, index) =>
                                            <option key={index} value={item}>Page {item}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        trackings: state.data.trackings,
    }
}

export default connect(mapStateToProps, { toggleOrderStatusFilter })(Orders);
