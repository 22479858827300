import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as otherApi from './../../api/other';

export class ManageClientTestimonials extends Component {

    constructor(props) {
        super(props)

        this.state = {
            members: [],
            name: '',
            title: '',
            image: null
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.fetchTeamMembers()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ members: res.data })
                    } else {
                        alert('Error Occured .. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured .. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    setImage = e => {
        let file = e.target.files[0];
        if (file != undefined) {
            if (
                file.type.toLowerCase() != 'image/jpg' &&
                file.type.toLowerCase() != 'image/jpeg' &&
                file.type.toLowerCase() != 'image/png'
            ) {
                this.setState({ image: null }, () => {
                    alert('Please upload a valid Image file..!')
                })
            } else {
                var _URL = window.URL || window.webkitURL;
                var img = new Image();
                var objectUrl = _URL.createObjectURL(file);
                const scope = this
                img.onload = function () {
                    if (this.width != 277 || this.height != 300 || file.size > 512000) {
                        scope.setState({ image: null }, () => {
                            alert('Please make sure the image is 277px x 300px and under 500 kb..!')
                        })
                    } else {
                        scope.setState({ image: file })
                    }
                    _URL.revokeObjectURL(objectUrl);
                };
                img.src = objectUrl;
            }
        } else {
            this.setState({ image: null })
        }
    }

    addTeamMember = async (e) => {
        e.preventDefault();

        if (this.state.image == null) {
            alert('Please re-select the image ..!')
        } else {
            if (window.confirm('Do you want to add the Team Member ?')) {

                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                let dataToUpload = {
                    name: this.state.name,
                    title: this.state.title
                }

                const formData = new FormData();
                formData.append('files', this.state.image)
                formData.append('dataToUplaod', JSON.stringify(dataToUpload))

                await otherApi.addTeamMember(formData)
                    .then(res => {
                        if (res.status == 200) {
                            alert('Team Member Added ..!')
                            spinner.style.display = "none";
                            window.location.reload();
                        } else {
                            alert('Something went wrong')
                            spinner.style.display = "none";
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        alert('Something went wrong')
                        spinner.style.display = "none";
                        window.location.reload();
                    })

            }
        }

    }

    deleteTeamMember = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Do you want to remove the Team Member ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.deleteTeamMember({ id: id })
                .then((res) => {
                    if (res.status == 200) {
                        alert("Team Member Deleted");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Team Member Does Not Exists') {
                        alert('Team Member does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    onChangeSequence = async (e, id) => {

        if (window.confirm('Do you want to update the sequence ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            var array = this.state.members
            var oldIndex = array.findIndex(item => item.id == id)
            var newIndex = e.target.value - 1

            if (newIndex >= array.length) {
                var k = newIndex - array.length + 1;
                while (k--) {
                    array.push(undefined);
                }
            }
            array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

            var newArray = []
            for (var i = 0; i < array.length; i++) {
                newArray.push({
                    name: array[i].name,
                    title: array[i].title,
                    image: array[i].image
                })
            }

            await otherApi.changeSequence({ newArray: newArray })
                .then(res => {
                    if (res.status == 200) {
                        alert("Sequence Updated");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Manage Team" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className='mb-3'>Add Team Member</h5>
                                    <form onSubmit={this.addTeamMember}>
                                        <label htmlFor='name'>Name* :</label>
                                        <input style={{ maxWidth: '450px' }} className="form-control mb-4" id="name" name="name" onChange={(e) => this.setState({ name: e.target.value })} required />
                                        <label htmlFor='name'>Post* :</label>
                                        <input style={{ maxWidth: '450px' }} className="form-control mb-4" id="title" name="title" onChange={(e) => this.setState({ title: e.target.value })} required />
                                        <label className='mt-1'>Select the Image*:</label>
                                        <input className="form-control" id="image" name="image" type="file" onChange={this.setImage} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Add Member
                                        </button>

                                    </form>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.members.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Title</th>
                                                    <th>Image</th>
                                                    <th>Sequence</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.members.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.title}</td>
                                                        <td className='pt-3 pb-3 pr-3 pl-3'><img style={{ width: '100px', borderRadius: '5%' }} src={`https://shivshahinetwork.com/assets/images/team/${item.image}`} alt={'image' + index} /></td>
                                                        <td>
                                                            <select className="form-control mt-3 mb-3" value={index + 1} onChange={e => this.onChangeSequence(e, item.id)} style={{ maxWidth: '250px' }}>
                                                                {this.state.members.map((member, index2) =>
                                                                    <option key={index2} value={index2 + 1}>{index2 + 1}</option>
                                                                )}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteTeamMember(e, item.id)} className='aicon'><i className="fa fa-close" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Member has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default ManageClientTestimonials;
