import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as productApi from './../../api/product';

export class ManageCategories extends Component {

    constructor(props) {
        super(props)

        this.state = {
            categories: this.props.cats,
            newcategory: '',
            oldCategory: '',
            updatedCategory: '',
            addorupdate: 'Add'
        }
    }

    componentDidMount = async () => {
        await checkUserTokenUtil();
    }

    addCategory = async (e) => {
        e.preventDefault();

        if (window.confirm('Add the Category ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const category = {
                category: this.state.newcategory,
            }

            await productApi.addCategory(category)
                .then(res => {
                    if (res.status == 200) {
                        alert('Category Added ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Category Already Exists') {
                        alert('This Category already exists..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Adding the category.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Adding the category.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updateCategory = async (e) => {
        e.preventDefault();

        if (window.confirm('Update the Category ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const categories = {
                oldCategory: this.state.oldCategory,
                updatedCategory: this.state.updatedCategory,
            }

            await productApi.editCategory(categories)
                .then(res => {
                    if (res.status == 200) {
                        alert('Category Updated ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Category Does Not Exists') {
                        alert('Old Category does not exists..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Updating the category.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Updating the category.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteCategory = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Delete the Category ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: id
            }

            await productApi.deleteCategory(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Category Deleted ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Category Does Not Exists') {
                        alert('This Category does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the category.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the category.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Categories" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value })} style={{ maxWidth: '250px' }}>
                                        <option value="Add">Add</option>
                                        <option value="Update">Update</option>
                                    </select>

                                    <hr />

                                    <div style={{ display: this.state.addorupdate == 'Add' ? '' : 'none' }}>
                                        <h5>Add Category</h5>
                                        <form onSubmit={this.addCategory}>
                                            <input style={{ maxWidth: '350px' }} className="form-control mt-4" id="newcategory" name="newcategory" onChange={(e) => this.setState({ newcategory: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.addorupdate == 'Update' ? '' : 'none' }}>
                                        <h5>Update Category</h5>
                                        <form onSubmit={this.updateCategory}>
                                            <label className='mt-3'>Old Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="oldCategory" name="oldCategory" onChange={(e) => this.setState({ oldCategory: e.target.value })} required />
                                            <label className='mt-3'>New Category:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedCategory" name="updatedCategory" onChange={(e) => this.setState({ updatedCategory: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.categories.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Category Name</th>
                                                    <th>Delete Category</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.categories.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.category}</td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteCategory(e, item.id)} className='aicon'><i className="fa fa-times" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Category has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

const mapStateToProps = (state) => ({
    cats: state.data.cats,
})

export default connect(mapStateToProps)(ManageCategories);
