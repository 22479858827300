import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Users } from 'react-feather'

import * as authApi from './../../api/auth'

import { checkUserTokenUtil } from '../../utils/utils';

export class TabsetProfile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: {},
            employee_list: [],
            final_list: [],
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            this.setState({ user: checkUserTokenUtilRes }, async () => {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";
                await authApi.fetchAdminUsers()
                    .then(res => {
                        if (res.status == 200) {
                            this.setState({ employee_list: res.data, final_list: res.data.filter(user => user.id != this.state.user.id) })
                        } else {
                            alert('Error Occured.. Please Try after some time')
                        }
                        spinner.style.display = "none";
                    })
                    .catch((err) => {
                        alert('Error Occured.. Please Try after some time')
                        spinner.style.display = "none";
                    })
            })
        }
    }

    onUpdateUser = async (e, id) => {
        if (this.state.user.authority == 'admin') {
            if (window.confirm('Do you want to update the User..?')) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";
                const data = {
                    id: id,
                    dataToUpdate: {
                        [e.target.name]: e.target.value,
                    }
                }
                await authApi.updateAdminUser(data)
                    .then(res => {
                        if (res.status == 200) {
                            alert('User Updated..!')
                            spinner.style.display = "none";
                            window.location.reload();
                        } else {
                            alert('Error Occured.. Please Try after some time')
                            window.location.reload();
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    })
            }
        } else {
            alert('You are not Authorised..!')
        }
    }

    render() {
        return (
            <div>
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <Tabs>
                    <TabList className="nav nav-tabs tab-coupon" >
                        <Tab className="nav-link"><User className="mr-2" />Profile</Tab>
                        <Tab className="nav-link"><Users className="mr-2" />Employee List</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="tab-pane fade show active">
                            <h5 className="f-w-600 f-16">Profile</h5>
                            <div className="table-responsive profile-table">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{this.state.user.name}</td>
                                        </tr>
                                        <tr>
                                            <td>E-mail:</td>
                                            <td>{this.state.user.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone:</td>
                                            <td>{this.state.user.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Authority:</td>
                                            <td>{this.state.user.authority}</td>
                                        </tr>
                                        <tr>
                                            <td>Location:</td>
                                            <td>Gangadarwaja, Aurangabad Rd, near Hdfc bank, Yeola, Maharashtra, 423401.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="card-body order-datatable lists-custom">
                            {this.state.final_list.length > 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Authority</th>
                                            <th>Verification</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.final_list.map((item, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.phone}</td>
                                                <td>
                                                    <select className="form-control" id="authority" name="authority" value={item.authority} onChange={(e) => this.onUpdateUser(e, item.id)} style={{ minWidth: '150px' }}>
                                                        <option value="admin">Admin</option>
                                                        <option value="employee">Employee</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control" id="isVerified" name="isVerified" value={item.isVerified} onChange={(e) => this.onUpdateUser(e, item.id)} style={{ minWidth: '150px' }}>
                                                        <option value="true">Approved</option>
                                                        <option value="false">Pending</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                :
                                <div>
                                    <p>No Employee has been Registered Yet.</p>
                                </div>
                            }
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default TabsetProfile
