import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as productApi from '../../api/product';

export class ManageColors extends Component {

    constructor(props) {
        super(props)

        this.state = {
            colors: [],
            newColor: '',
            oldColor: '',
            updatedColor: '',
            addorupdate: 'Add'
        }
    }

    componentDidMount = async () => {
        await this.fetchColors();
        await checkUserTokenUtil();
    }

    fetchColors = async () => {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        await productApi.fetchColors()
            .then(res => {
                if (res.status == 200) {
                    if (res.data) {
                        this.setState({
                            colors: res.data,
                            oldColor: res.data[0].prdColor
                        })
                    }
                    spinner.style.display = "none";
                } else {
                    alert('Error Occured While fetching the color.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured While fetching the color.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    addColor = async (e) => {
        e.preventDefault();

        if (window.confirm('Add Color?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const color = {
                color: this.state.newColor,
            }

            await productApi.addColor(color)
                .then(res => {
                    if (res.status == 200) {
                        alert('Color Added ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Color Already Exists') {
                        alert('This Color already exists..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Adding the color.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Adding the color.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updateColor = async (e) => {
        e.preventDefault();

        if (window.confirm('Update Color?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const colors = {
                oldColor: this.state.oldColor,
                updatedColor: this.state.updatedColor,
            }

            console.log(colors)
            await productApi.editColor(colors)
                .then(res => {
                    console.log(res);
                    if (res.status == 200) {
                        alert(res.msg)
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert(res.msg)
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Updating the color.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteColor = async (e, prdColor) => {
        e.preventDefault();

        if (window.confirm('Delete Color?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                prdColor: prdColor
            }

            await productApi.deleteColor(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Color Deleted ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Color Does Not Exists') {
                        alert('This Color does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the Color.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the Color.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Colors" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value })} style={{ maxWidth: '250px' }}>
                                        <option value="Add">Add</option>
                                        <option value="Update">Update</option>
                                    </select>

                                    <hr />

                                    <div style={{ display: this.state.addorupdate == 'Add' ? '' : 'none' }}>
                                        <h5>Add Color</h5>
                                        <form onSubmit={this.addColor}>
                                            <input style={{ maxWidth: '350px' }} className="form-control mt-4" id="newColor" name="newColor" onChange={(e) => this.setState({ newColor: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.addorupdate == 'Update' ? '' : 'none' }}>
                                        <h5>Update Color</h5>
                                        <form onSubmit={this.updateColor}>
                                            <label className='mt-3'>Old Color:</label>
                                            <select className="form-control" id="oldColor" name="oldColor" value={this.state.oldColor} onChange={(e) => {
                                                this.setState({ oldColor: e.target.value })
                                            }} style={{ maxWidth: '250px' }} required>
                                                {this.state.colors.map((color, index) => <option key={index} value={color.prdColor}>{color.prdColor}</option>)}
                                            </select>
                                            <label className='mt-3'>New Color:</label>
                                            <input style={{ maxWidth: '350px' }} className="form-control" id="updatedColor" name="updatedColor" onChange={(e) => this.setState({ updatedColor: e.target.value })} required />
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.colors.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Color Name</th>
                                                    <th>Delete Color</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.colors.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.prdColor}</td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteColor(e, item.prdColor)} className='aicon'><i className="fa fa-times" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Color has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

const mapStateToProps = (state) => ({
    colors: state.data.colors,
})

export default connect(mapStateToProps)(ManageColors);
