import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as bishiApi from '../../api/bishi';

export class ManageBhishi extends Component {

    constructor(props) {
        super(props)

        this.state = {
            bishis: [],
            oldName: '',
            newName: '',
            newDescription: '',
            updateName: '',
            updateDescription: '',
            isActive: '',
            addorupdate: 'Add',
            selectedBishiToUpdate: {}
        }
    }

    componentDidMount = async () => {
        await this.fetchBishiSchemes();
        await checkUserTokenUtil();
    }

    fetchBishiSchemes = async () => {
        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        await bishiApi.fetchBishiSchemes()
            .then(res => {
                console.log(res)
                if (res.status == 200) {
                    this.setState({
                        bishis: res.data,
                        updateName: res.data[0].name,
                        updateDescription: res.data[0].description,
                        selectedBishiToUpdate: res.data[0]
                    })
                    spinner.style.display = "none";
                } else {
                    alert('Error Occured While fetching the bhishi schemes.. Please Try after some time')
                    spinner.style.display = "none";
                }
            })
            .catch((err) => {
                alert('Error Occured While fetching the bhishi schemes.. Please Try after some time')
                spinner.style.display = "none";
            })
    }

    addBishiScheme = async (e) => {
        e.preventDefault();

        if (window.confirm('Add Bishi scheme?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const bishi = {
                name: this.state.newName,
                description: this.state.newDescription,
            }

            await bishiApi.addBishiScheme(bishi)
                .then(res => {
                    if (res.status == 200) {
                        alert('Bihsi scheme Added ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Bihsi scheme Already Exists') {
                        alert('This Bihsi scheme already exists..!')
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Adding the Bihsi scheme.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Adding the Bihsi scheme.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    updateBhishiScheme = async (e) => {
        e.preventDefault();
        if (window.confirm('Update Bhishi Scheme?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const bhishi = {
                oldName: this.state.oldName,
                updatedName: this.state.updateName,
                updatedDesc: this.state.updateDescription,
                id: this.state.selectedBishiToUpdate.id
            }

            await bishiApi.editBishiSchemes(bhishi)
                .then(res => {
                    if (res.status == 200) {
                        alert('Bhishi Scheme Updated ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        console.log(res)
                        alert(res.error)
                        //window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Updating the Bhishi Scheme.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    deleteBishiScheme = async (e, id) => {

        e.preventDefault();

        if (window.confirm('Delete Bihsi scheme?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: id
            }

            await bishiApi.deleteBishiScheme(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Bihsi scheme Deleted ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Bihsi scheme Does Not Exists') {
                        alert('This Bihsi scheme does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the Bihsi scheme.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the Bihsi scheme.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    toggleBishiScheme = async (e, id, isActive) => {

        e.preventDefault();

        if (window.confirm('Update Bihsi scheme?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                id: id,
                isActive: !isActive
            }

            await bishiApi.toggleBishiScheme(data)
                .then(res => {
                    if (res.status == 200) {
                        //alert('Bihsi scheme updated ..! Please refresh the page after fully loaded..')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Bihsi scheme Does Not Exists') {
                        alert('This Bihsi scheme does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Updating the Bihsi scheme.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Updating the Bihsi scheme.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }


    render() {
        return (
            <Fragment>
                <Breadcrumb title="Bihsi Schemes" parent="Bishi" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <select className="form-control" id="addorupdate" name="addorupdate" value={this.state.addorupdate} onChange={(e) => this.setState({ addorupdate: e.target.value })} style={{ maxWidth: '250px' }}>
                                        <option value="Add">Add</option>
                                        <option value="Update">Update</option>
                                    </select>

                                    <hr />

                                    <div style={{ display: this.state.addorupdate == 'Add' ? '' : 'none' }}>
                                        <h5>Add Bhishi Scheme</h5>
                                        <form onSubmit={this.addBishiScheme}>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-sm-4">
                                                    Name :
                                                </label>
                                                <div className="col-xl-8 col-sm-7">
                                                    <input
                                                        className="form-control"
                                                        name="name"
                                                        type="text"
                                                        placeholder="Enter Bhishi Name"
                                                        required
                                                        onChange={(e) => this.setState({ newName: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-sm-4">
                                                    Description :
                                                </label>
                                                <div className="col-xl-8 col-sm-7 description-sm">
                                                    <textarea
                                                        className="form-control mb-0"
                                                        name="description"
                                                        id="description"
                                                        type="text"
                                                        onChange={(e) => this.setState({ newDescription: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Add
                                            </button>
                                        </form>
                                    </div>
                                    <div style={{ display: this.state.addorupdate == 'Update' ? '' : 'none' }}>
                                        <h5>Update Bhishi Scheme</h5>
                                        <form onSubmit={this.updateBhishiScheme}>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-sm-4">
                                                    Name :
                                                </label>
                                                <div className="col-xl-8 col-sm-7">
                                                    {this.state.bishis.length > 0 ?
                                                        <select
                                                            required
                                                            className="form-control mb-0"
                                                            name="bhishi"
                                                            id="bhishi"
                                                            onChange={(e) => {
                                                                var bhishiItem = this.state.bishis[e.target.selectedIndex];
                                                                this.setState({
                                                                    selectedBishiToUpdate: bhishiItem,
                                                                    updateName: bhishiItem.name,
                                                                    updateDescription: bhishiItem.description
                                                                })
                                                            }}
                                                        >
                                                            {
                                                                this.state.bishis.map((bhishi, index) =>
                                                                    <option key={index}>{bhishi.name}</option>
                                                                )
                                                            }
                                                        </select> : ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-sm-4">
                                                    Name :
                                                </label>
                                                <div className="col-xl-8 col-sm-7">
                                                    <input
                                                        className="form-control"
                                                        name="name"
                                                        type="text"
                                                        placeholder="Enter New Bhishi Name"
                                                        required
                                                        onChange={(e) => this.setState({ updateName: e.target.value })}
                                                        value={this.state.updateName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-sm-4">
                                                    Description :
                                                </label>
                                                <div className="col-xl-8 col-sm-7 description-sm">
                                                    <textarea
                                                        className="form-control mb-0"
                                                        name="description"
                                                        id="description"
                                                        type="text"
                                                        onChange={(e) => this.setState({ updateDescription: e.target.value })}
                                                        value={this.state.updateDescription}
                                                    />
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary mt-3">
                                                Update
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.bishis.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Bishi Name</th>
                                                    <th>Bishi Description</th>
                                                    <th>Active</th>
                                                    <th>Delete Bishi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.bishis.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.description}</td>
                                                        <td><input className="form-check-input" type="checkbox" name='Active' checked={item.isActive} onChange={(e) => this.toggleBishiScheme(e, item.id, item.isActive)} /></td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteBishiScheme(e, item.id)} className='aicon'><i className="fa fa-times" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Bishi scheme has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

const mapStateToProps = (state) => ({
    bishis: state.data.bishis,
})

export default connect(mapStateToProps)(ManageBhishi);
