import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react'

import Breadcrumb from '../common/breadcrumb';

import * as orderApi from './../../api/order'

import address_img from './../../assets/images/address-box.jpg'

import { checkUserTokenUtil } from '../../utils/utils';

export class OrderDetailed extends Component {

    constructor(props) {
        super(props)

        this.state = {
            order: {},
            items: [],
            itemsList: [],
            upline: [],
            profits: [],
            plan: '',
            quantity: 0,
            vendorsList: [],

            temp_trackingid: '',
            temp_service: '',

            newModifiedProducts: [],
            old_payment_receipts: null
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            let slug = this.props.match.params.slug;

            const orderDetails = {
                id: slug,
            }

            await orderApi.fetchVendors()
                .then(async response => {
                    var vendorsList = response.data
                    if (vendorsList) {
                        await orderApi.fetchSingleOrder(orderDetails)
                            .then(res => {
                                if (res.status === 200) {
                                    var singleOrder = res.data

                                    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                                    var orderDate = new Date(singleOrder.order_date);
                                    var deliveryDate = new Date(singleOrder.delivery_date);
                                    var orderDateFinal = days[orderDate.getDay()] + ', ' + monthNames[orderDate.getMonth()] + ' ' + orderDate.getDate() + ', ' + orderDate.getFullYear();
                                    var deliveryDateFinal = days[deliveryDate.getDay()] + ', ' + monthNames[deliveryDate.getMonth()] + ' ' + deliveryDate.getDate() + ', ' + deliveryDate.getFullYear();

                                    singleOrder.order_date = orderDateFinal
                                    singleOrder.delivery_date = deliveryDateFinal

                                    this.setState({
                                        order: singleOrder,
                                        items: JSON.parse(singleOrder.items),
                                        itemsList: singleOrder.itemsList,
                                        upline: JSON.parse(singleOrder.upline),
                                        profits: JSON.parse(singleOrder.profits),
                                        plan: JSON.parse(singleOrder.profits)[0].scheme,
                                        temp_trackingid: singleOrder.tracking_id,
                                        temp_service: singleOrder.service,
                                        old_payment_receipts: singleOrder.payment_receipts,
                                        vendorsList: vendorsList
                                    }, () => {
                                        var totalQty = 0;

                                        this.state.items.map((item, index) => {
                                            totalQty = parseInt(totalQty) + parseInt(item.qty)
                                        })
                                        this.setState({ quantity: totalQty }, () => {
                                            spinner.style.display = "none";
                                        })

                                    })
                                } else {
                                    alert('Something Went Wrong : status')
                                    //this.props.history.push(`${process.env.PUBLIC_URL}/sales/orders`)
                                }
                            })
                            .catch((err) => {
                                alert('Something Went Wrong : fetch' + JSON.stringify(err))
                                //this.props.history.push(`${process.env.PUBLIC_URL}/sales/orders`)
                            })
                    } else {
                        alert('Something Went Wrong : status')
                        //this.props.history.push(`${process.env.PUBLIC_URL}/sales/orders`)
                    }
                })
                .catch(err => {
                    alert('Something Went Wrong : status')
                    //this.props.history.push(`${process.env.PUBLIC_URL}/sales/orders`)
                })
        }
    }

    onChangeStatus = async (e) => {

        let spinner = document.getElementById("spinneranimid");

        if (e.target.name == 'payment_status') {
            if (window.confirm(`Update the payment status ?`)) {
                spinner.style.display = "block";
                var newModifiedProducts = [];

                this.state.items.map((item, index) => {
                    newModifiedProducts.push({ id: item.id, qty: parseInt(item.qty) })
                })
                var dataPS = {
                    stockData: newModifiedProducts,
                    id: this.state.order.id,
                    display_order_id: parseInt(this.state.order.id) + 9999,
                    payment_status: e.target.value,
                    isOkToUpdateStock: false
                }

                if (window.confirm(`Do you want to ${e.target.value == 'Completed' ? 'reduce' : 'increase'} the Stock ?`)) {
                    dataPS.isOkToUpdateStock = true
                } else {
                    dataPS.isOkToUpdateStock = false
                }
                await orderApi.updatePaymentStatus(dataPS)
                    .then((res) => {
                        if (res.status == 200) {
                            alert('Payment Status Updated..!')
                            spinner.style.display = "none";
                            window.location.reload()
                        } else {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    })
            }
        } else {
            if (this.state.order.status != 'Cancelled') {
                if (window.confirm(`Update the order status ?`)) {
                    spinner.style.display = "block";
                    var dataOS = {
                        id: this.state.order.id,
                        display_order_id: parseInt(this.state.order.id) + 9999,
                        status: e.target.value
                    }
                    await orderApi.updateOrderStatus(dataOS)
                        .then((res) => {
                            if (res.status == 200) {
                                alert('Status Updated ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            } else if (res.status == 402) {
                                if (res.error == 'status is cancelled') {
                                    alert('Not allowed to change status after cancellation..!')
                                } else if (res.error == 'already distributed') {
                                    alert('After Distribution, order is not allowed to be Cancelled..!')
                                } else if (res.error == 'payment needed') {
                                    alert('Payment should be completed first..!')
                                }
                                spinner.style.display = "none";
                            } else {
                                alert('Something Went Wrong ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            }
                        })
                        .catch((err) => {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        })
                }
            } else {
                alert('Not allowed to change status after cancellation..!')
            }
        }

    }

    trackingUpdate = async (e) => {
        e.preventDefault();

        if (window.confirm('Do you want to update the tracking ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            var data = {
                id: this.state.order.id,
                display_order_id: parseInt(this.state.order.id) + 9999,
                tracking_link: this.props.trackings[this.props.trackings.findIndex(item => item.service == this.state.temp_service)].link,
                updateData: {
                    tracking_id: this.state.temp_trackingid,
                    service: this.state.temp_service,
                }
            }

            await orderApi.updateOrderTracking(data)
                .then((res) => {
                    if (res.status == 200) {
                        alert('Tracking Updated ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    } else {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Something Went Wrong ..!')
                    window.location.reload()
                    spinner.style.display = "none";
                })
        }
    }

    distributeProfit = async (e) => {
        if (e.target.value == 'true') {
            if (this.state.order.payment_status == 'Completed' && (this.state.order.status == 'Shipped' || this.state.order.status == 'Delivered')) {
                if (window.confirm('Do you want to Distribute the profits ?')) {

                    let spinner = document.getElementById("spinneranimid");
                    spinner.style.display = "block";

                    const data = {
                        id: this.state.order.id,
                        display_order_id: parseInt(this.state.order.id) + 9999,
                        dataToUpdate: { isDistributed: true }
                    }
                    await orderApi.distributeProfit(data)
                        .then((res) => {
                            if (res.status == 200) {
                                alert('Profits Distributed ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            } else {
                                alert('Something Went Wrong ..!')
                                window.location.reload()
                                spinner.style.display = "none";
                            }
                        })
                        .catch((err) => {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        })

                }
            } else {
                alert('Order must be Shipped and Payment should be completed..!')
            }
        } else {
            alert('You can not revert profit distribution changes when already done')
        }
    }

    updateItemVendor = async (e, item) => {
        if (e.target.value == '') { } else {
            if (window.confirm('Do you want to update the vendor?')) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";


                var data = {
                    orderItemId: item.orderItemId,
                    orderItemVendor: e.target.value,
                    id: item.id,
                }

                await orderApi.updateItemVendor(data)
                    .then((res) => {
                        if (res.status == 200) {
                            window.location.reload()
                            spinner.style.display = "none";
                        } else {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    })
            }
        }
    }

    updateItemTracking = async (e, inputId, item) => {
        var trackingIdElem = document.getElementById(inputId)
        if (trackingIdElem.value) {
            if (window.confirm('Do you want to update tracking id?')) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";


                var data = {
                    orderItemId: item.orderItemId,
                    orderItemTrackingId: trackingIdElem.value,
                    id: item.id,
                }

                await orderApi.updateItemTracking(data)
                    .then((res) => {
                        if (res.status == 200) {
                            window.location.reload()
                            spinner.style.display = "none";
                        } else {
                            alert('Something Went Wrong ..!')
                            //window.location.reload()
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    })
            }
        } else {
            window.confirm('Please enter correct tracking id.')
        }
    }

    updateItemStatus = async (e, item) => {
        if (window.confirm('Do you want to update the order status?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            var data = {
                orderItemId: item.orderItemId,
                orderItemStatus: e.target.value,
                id: item.id,
                orderItemPrice: item.orderItemPrice
            }

            await orderApi.updateItemStatus(data)
                .then((res) => {
                    if (res.status == 200) {
                        alert('Status Updated ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    } else if (res.status == 402) {
                        if (res.error == 'status is cancelled') {
                            alert('Not allowed to change status after cancellation..!')
                        } else if (res.error == 'already distributed') {
                            alert('After Distribution, order is not allowed to be Cancelled..!')
                        } else if (res.error == 'payment needed') {
                            alert('Payment should be completed first..!')
                        }
                        spinner.style.display = "none";
                    } else {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    } spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Something Went Wrong ..!')
                    window.location.reload()
                    spinner.style.display = "none";
                })
        }
    }

    updateItemCourierService = async (e, item) => {
        if (e.target.value) {
            if (window.confirm('Do you want to update the order courier service?')) {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                var data = {
                    orderItemId: item.orderItemId,
                    orderItemService: e.target.value,
                    id: item.id,
                }

                await orderApi.updateItemCourierService(data)
                    .then((res) => {
                        if (res.status == 200) {
                            window.location.reload()
                            spinner.style.display = "none";
                        } else {
                            alert('Something Went Wrong ..!')
                            window.location.reload()
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Something Went Wrong ..!')
                        window.location.reload()
                        spinner.style.display = "none";
                    })
            }
        }
    }

    viewImages = () => {
        if (this.state.old_payment_receipts != null) {
            JSON.parse(this.state.old_payment_receipts).map((image, index) => {
                window.open(`https://shivshahinetwork.com/assets/images/order-payment-receipts/${image}`);
            })
        } else {
            alert('There are no Old receipts uploaded')
        }
    }

    render() {

        return (
            <Fragment>
                <Breadcrumb title="Order Details" parent="Sales" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Order ID:&nbsp;&nbsp;{parseInt(this.state.order.id) + 9999}</h5>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    <div className="custom-order-details">
                                        <h4>Order Details</h4>
                                        <ul>
                                            <li>Order Date :&nbsp;&nbsp;<span>{this.state.order.order_date}</span></li>
                                            <li style={{ display: `${this.state.order.status == 'Cancelled' ? 'none' : ''}` }}>Delivery Date:&nbsp;&nbsp;<span>{this.state.order.delivery_date}</span></li>
                                        </ul>

                                        <h4 className="pt-3">Payment Details</h4>
                                        <ul className="pb-3">
                                            <li>Subtotal :&nbsp;&nbsp;<span>₹&nbsp;&nbsp;{this.state.order.subtotal}</span></li>
                                            <li>Shipping :&nbsp;&nbsp;<span>₹&nbsp;&nbsp;{this.state.order.shipping}</span></li>
                                            <li>Total :&nbsp;&nbsp;<span>₹&nbsp;&nbsp;{this.state.order.total}</span></li>
                                            <li>Hirkani :&nbsp;&nbsp;<span>₹&nbsp;&nbsp;{this.state.order.hirkani}</span></li>
                                            <li>Wallet :&nbsp;&nbsp;<span>₹&nbsp;&nbsp;{this.state.order.wallet}</span></li>
                                            <li>Grand Total :&nbsp;&nbsp;<span>₹&nbsp;&nbsp;{this.state.order.grandtotal}</span></li>
                                        </ul>

                                        <h4 className='mt-2 mb-3'>Payment Receipts</h4>
                                        <div><strong style={{ fontSize: '15px', color: '#c96', cursor: 'pointer' }} onClick={this.viewImages}>Click Here</strong></div>

                                        <h4 className="pt-3 pb-2">Shipping Address</h4>
                                        <div className='address-box'>
                                            <img src={address_img} alt="" />
                                            <div className='one'>
                                                <h3>
                                                    NAME : {this.state.order.firstname} {this.state.order.lastname}<br />
                                                    ADDRESS : {this.state.order.address}<br />
                                                    CITY: {this.state.order.city} - {this.state.order.pincode}.<br />
                                                    MO : {this.state.order.phone}
                                                </h3>
                                            </div>
                                            <div className='two'>
                                                <h6>{this.state.quantity}</h6>
                                                <h6>{this.state.order.id ? parseInt(this.state.order.id) + 9999 : ''}</h6>
                                                <h6 style={{ paddingLeft: '43px' }}>{this.state.order.service}</h6>
                                            </div>
                                        </div>

                                        <h4 className="pt-3">Profit Structure - <strong style={{ color: '#5bad0e' }}>{this.state.plan}</strong></h4>
                                        <ul className="pb-3">
                                            {this.state.profits.map((item, index) =>
                                                index != 0 ?
                                                    <li key={index}>
                                                        <p style={{ display: item.name == '' || parseInt(item.profit) == 0 ? 'none' : '' }}>
                                                            Upline {index} :&nbsp;&nbsp;<span>₹&nbsp;{item.profit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> - ({item.name} - {item.phone})
                                                        </p>
                                                    </li>
                                                    :
                                                    <li key={index}>
                                                        <p>
                                                            Customer Profit :&nbsp;&nbsp;<span>₹&nbsp;{item.profit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> - ({this.state.order.userData.name} - {this.state.order.userData.phone})
                                                        </p>
                                                    </li>
                                            )}
                                        </ul>

                                        <h4 className='mt-2'>Profit Distribution Status: <strong style={{ color: '#c96' }}>{this.state.order.isDistributed ? 'Distributed' : 'Pending'}</strong></h4>
                                        <select className="form-control" id="settlements" name="profitdistribution" value={this.state.order.isDistributed} onChange={this.distributeProfit} style={{ maxWidth: '250px', display: this.state.order.isDistributed || this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                            <option value='false'>Pending</option>
                                            <option value="true">Distributed</option>
                                        </select>

                                        <hr />
                                        <h4>Order Details</h4>
                                        <div className="mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className='first'>Product</th>
                                                        <th>Price</th>
                                                        <th>Size</th>
                                                        <th>Sku</th>
                                                        <th>Color</th>
                                                        <th>Qty</th>
                                                        <th>Total</th>
                                                        <th>Vendor</th>
                                                        <th>Order Status</th>
                                                        <th>Tracking ID</th>
                                                        <th>Courier Service</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.itemsList.length ? this.state.itemsList : this.state.items).map((item, index) =>
                                                        <tr key={index}>
                                                            <td className='first'>
                                                                <a href={`${process.env.PUBLIC_URL}/products/product-detail/${item.productId ? item.productId : item.id}`} target="_blank" rel="noopener noreferrer">
                                                                    <figure>
                                                                        {(item.orderItemPictures ? JSON.parse(item.orderItemPictures) : item.pictures).map((picture, index2) =>
                                                                            <img key={index2} style={{ width: '100px' }} src={`https://shivshahinetwork.com/${picture}`} alt="Product" />
                                                                        )}
                                                                    </figure>
                                                                    <h6>
                                                                        {item.orderItemName ? item.orderItemName : item.name}
                                                                    </h6>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                ₹&nbsp;
                                                                {(item.orderItemPrice ? item.orderItemPrice : item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                            </td>
                                                            <td>{item.orderItemSize ? item.orderItemSize : item.size} Yards</td>
                                                            <td><strong>{item.orderItemSku ? item.orderItemSku : item.sku}</strong></td>
                                                            <td><div className="color-display" style={{ backgroundColor: item.orderItemColor ? item.orderItemColor : item.color }} /></td>
                                                            <td>1</td>
                                                            <td>
                                                                <strong>
                                                                    ₹&nbsp;
                                                                    {(item.orderItemSum ? item.orderItemSum : item.sum).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </strong>
                                                            </td>
                                                            <td>
                                                                {
                                                                    ('orderItemVendor' in item) ?
                                                                        <select className='form-control' value={(item.orderItemVendor ? item.orderItemVendor : "")} style={{ minWidth: '100px' }} onChange={(e) => this.updateItemVendor(e, item)}>
                                                                            <option value="">-- Select Vendor --</option>
                                                                            {
                                                                                this.state.vendorsList.map((vendorName, index) => {
                                                                                    return (<option key={index} value={vendorName}>{vendorName}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                        : <div></div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.orderItemStatus ?
                                                                        <div>
                                                                            <strong>
                                                                                <span
                                                                                    style={{
                                                                                        color: (item.orderItemStatus ? item.orderItemStatus : "") == 'Placed' ? '#255d9a' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Processed' ? '#7300b0' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Packed' ? '#aa8f40' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Shipped' ? '#7bab00' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Delivered' ? '#137d00' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Exchange in Progress' ? '#fff' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Return in Progress' ? '#fff' : (item.orderItemStatus ? item.orderItemStatus : "") == 'On Hold' ? '#ff2121' : '#3d4246',
                                                                                        backgroundColor: (item.orderItemStatus ? item.orderItemStatus : "") == 'Placed' ? '#cce5ff' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Processed' ? '#e4b6fc' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Packed' ? '#fff3cd' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Shipped' ? '#deff8a' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Delivered' ? '#7bff63' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Exchange in Progress' ? '#dba100' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Return in Progress' ? '#ff6666' : (item.orderItemStatus ? item.orderItemStatus : "") == 'On Hold' ? '#ff9191' : '#e2e3e5',
                                                                                        padding: '8px 8px 8px 8px'
                                                                                    }}
                                                                                >{(item.orderItemStatus ? item.orderItemStatus : "") == 'Exchange in Progress' ? 'Exchange' : (item.orderItemStatus ? item.orderItemStatus : "") == 'Return in Progress' ? 'Return' : (item.orderItemStatus ? item.orderItemStatus : "")}</span></strong>
                                                                            <select className="form-control mt-2" id="order_status_select" onChange={(e) => this.updateItemStatus(e, item)} name="order_status_select" value={(item.orderItemStatus ? item.orderItemStatus : "")} style={{ minWidth: '100px', display: (item.orderItemStatus ? item.orderItemStatus : "") == 'Return in Progress' || (item.orderItemStatus ? item.orderItemStatus : "") == 'Exchange in Progress' ? 'none' : '' }}>
                                                                                <option value="Placed">Placed</option>
                                                                                <option value="Processed">Processed</option>
                                                                                {/* <option value="Packed">Packed</option> */}
                                                                                <option value="Shipped">Shipped</option>
                                                                                <option value="Delivered">Delivered</option>
                                                                                <option value="Cancelled">Cancelled</option>
                                                                                <option value="On Hold">On Hold</option>
                                                                            </select>
                                                                        </div>
                                                                        : <div></div>
                                                                }
                                                            </td>
                                                            <td>{
                                                                ('orderItemTrackingId' in item) ?
                                                                    <div>
                                                                        <div>
                                                                            {item.orderItemTrackingId ? item.orderItemTrackingId : ""}
                                                                        </div>
                                                                        <div style={{ display: this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                                            <input className='form-control' pattern=".*\S+.*" id={`tracking_id_update_${index}`} />
                                                                            <button className='btn btn-primary mt-3' onClick={e => this.updateItemTracking(e, `tracking_id_update_${index}`, item)}>
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </div> : <div></div>
                                                            }</td>
                                                            <td>
                                                                {
                                                                    ('orderItemService' in item) ?
                                                                        <div>
                                                                            {this.props.trackings.map((trackinglist, index) =>
                                                                                <a key={index} href={trackinglist.link} target="_blank" rel="noopener noreferrer" style={{ display: item.orderItemService == trackinglist.service ? '' : 'none', fontSize: '15px', color: '#c96' }}>{trackinglist.service}</a>
                                                                            )}

                                                                            <select className="form-control" id="servicestatuschange" name="temp_service" value={item.orderItemService} onChange={e => this.updateItemCourierService(e, item)} required style={{ display: this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                                                <option value="">-- Select Service --</option>
                                                                                {this.props.trackings.map((item, index) =>
                                                                                    <option key={index} value={item.service}>{item.service}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        : <div></div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <div className="order-statuss" >

                                            <form onSubmit={this.trackingUpdate}>
                                                <h4>Current Payment Status : <strong style={{ color: this.state.order.payment_status == 'Completed' ? 'green' : 'red' }}>{this.state.order.payment_status}</strong></h4>
                                                <select className="form-control" id="paymentstatuschange" name="payment_status" value={this.state.order.payment_status} onChange={this.onChangeStatus} style={{ display: this.state.order.isDistributed || this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                    <option value="Completed">Completed</option>
                                                    <option value="Pending">Pending</option>
                                                </select>

                                                <h4>Current Order Status : <strong>{this.state.order.status}</strong></h4>
                                                <select className="form-control" id="statuschange" name="status" value={this.state.order.status} onChange={this.onChangeStatus} style={{ display: this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                    <option value="Placed">Placed</option>
                                                    <option value="Processed">Processed</option>
                                                    {/* <option value="Packed">Packed</option> */}
                                                    <option value="Shipped">Shipped</option>
                                                    <option value="Delivered">Delivered</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                    <option value="On Hold">On Hold</option>
                                                </select>

                                                <hr />

                                                <h4>Tracking ID : <strong >{this.state.order.tracking_id}</strong></h4>
                                                <input className="form-control" id="trackingidchange" name="temp_trackingid" pattern=".*\S+.*" value={this.state.temp_trackingid} onChange={e => this.setState({ temp_trackingid: e.target.value.replace(new RegExp("\\s*", "gm"), '') })} required style={{ display: this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }} />

                                                <h4>Courier Service : <strong>{this.state.order.service}</strong></h4>
                                                <select className="form-control" id="servicestatuschange" name="temp_service" value={this.state.temp_service} onChange={e => this.setState({ temp_service: e.target.value })} required style={{ display: this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                    <option value="">-- Select Service --</option>
                                                    {this.props.trackings.map((item, index) =>
                                                        <option key={index} value={item.service}>{item.service}</option>
                                                    )}
                                                </select>

                                                <button type="submit" className="btn btn-primary mt-3" style={{ display: this.state.order.status == 'Return in Progress' || this.state.order.status == 'Exchange in Progress' ? 'none' : '' }}>
                                                    Update Tracking
                                                </button>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >

            </Fragment >
        )

    }
}

const mapStateToProps = (state) => ({
    products: state.data.products,
    symbol: state.data.symbol,
    trackings: state.data.trackings,
})

export default connect(mapStateToProps)(OrderDetailed);

