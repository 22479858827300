import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';

import store from "./store";
import { getAllProducts, getAllCategoriesCustom, getAllTrackings, toggleDeleteProductCart, getAllFilterColorsCustom } from "./actions";

import Login from './components/auth/login';

import ProductList from './components/manageProducts/product-list';
import AddProduct from './components/manageProducts/add-product';
import ProductDetail from './components/manageProducts/product-detail';
import EditProduct from './components/manageProducts/edit-product';
import DeleteProducts from './components/manageProducts/delete-product-cart'
import SearchProduct from './components/manageProducts/search-product'

import Orders from './components/sales/orders';
import OrderDetailed from './components/sales/order-detailed';
import Transactions from './components/sales/transactions';

import ManageCategories from './components/utilities/manageCategories'
import ManageColors from './components/utilities/manageColors'
import ManageNetworks from './components/utilities/manageNetworks';
import ManageSpecialTxns from './components/utilities/manageSpecialTxns'
import ManageTrackings from './components/utilities/manageTrackings'
import ManageLinks from './components/utilities/manageLinks'
// import ManageIntroSliders from './components/utilities/manageIntroSliders'
import ManageClientTestimonials from './components/utilities/manageClientTestimonials'
import ManageNotifications from './components/utilities/manageNotifications'
import ManageTeamMembers from './components/utilities/manageTeamMembers'
import ManageCatImages from './components/utilities/manageCatImages';

import UserList from './components/users/userList';

import RechargeRequests from './components/requests/rechargeRequests';
import WithdrawlRequests from './components/requests/withdrawlRequests';
import ReturnExchangeRequests from './components/requests/returnExchangeRequests';
import ReturnExchangeRequestDetailed from './components/requests/returnExchangeRequestDetailed'

import SmsService from './components/dashboard/smsService';
import ShortUrlService from './components/dashboard/shortUrlService'

import Profile from './components/settings/profile';
import { ManageBhishi } from './components/bhishi/manageBhishi';
import { BhishiRequests } from './components/bhishi/bhishiRequests';

class Root extends Component {
    render() {
        store.dispatch(getAllProducts());
        store.dispatch(getAllCategoriesCustom());
        store.dispatch(getAllTrackings());
        store.dispatch(getAllFilterColorsCustom());
        // store.dispatch(toggleDeleteProductCart());
        return (
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                            <App>
                                <Route exact path={`${process.env.PUBLIC_URL}/products/product-list`} component={ProductList} />
                                <Route path={`${process.env.PUBLIC_URL}/products/product-detail/:id`} component={ProductDetail} />
                                <Route exact path={`${process.env.PUBLIC_URL}/products/add-product`} component={AddProduct} />
                                <Route path={`${process.env.PUBLIC_URL}/products/update-product/:id`} component={EditProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/products/delete-cart`} component={DeleteProducts} />
                                <Route exact path={`${process.env.PUBLIC_URL}/products/search-product`} component={SearchProduct} />

                                <Route exact path={`${process.env.PUBLIC_URL}/sales/orders`} component={Orders} />
                                <Route path={`${process.env.PUBLIC_URL}/sales/order-detail:slug`} component={OrderDetailed} />
                                <Route exact path={`${process.env.PUBLIC_URL}/sales/transactions`} component={Transactions} />

                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-categories`} component={ManageCategories} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-colors`} component={ManageColors} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-networks`} component={ManageNetworks} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-specialtxns`} component={ManageSpecialTxns} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-trackings`} component={ManageTrackings} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-links`} component={ManageLinks} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-introsliders`} component={ManageIntroSliders} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-clienttesimonials`} component={ManageClientTestimonials} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-notifications`} component={ManageNotifications} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-team-members`} component={ManageTeamMembers} />
                                <Route exact path={`${process.env.PUBLIC_URL}/utilities/manage-cat-images`} component={ManageCatImages} />

                                <Route exact path={`${process.env.PUBLIC_URL}/bhishi/manage-bhishi`} component={ManageBhishi} />
                                <Route exact path={`${process.env.PUBLIC_URL}/bhishi/bhishi-requests`} component={BhishiRequests} />

                                <Route exact path={`${process.env.PUBLIC_URL}/user-list`} component={UserList} />

                                <Route exact path={`${process.env.PUBLIC_URL}/requests/recharge`} component={RechargeRequests} />
                                <Route exact path={`${process.env.PUBLIC_URL}/requests/withdrawl`} component={WithdrawlRequests} />
                                <Route exact path={`${process.env.PUBLIC_URL}/requests/returnexchange`} component={ReturnExchangeRequests} />
                                <Route path={`${process.env.PUBLIC_URL}/requests/return-exchange-request-detail:slug`} component={ReturnExchangeRequestDetailed} />

                                <Route exact path={`${process.env.PUBLIC_URL}/dashboard/sms-service`} component={SmsService} />
                                <Route exact path={`${process.env.PUBLIC_URL}/dashboard/short-url-service`} component={ShortUrlService} />

                                <Route path={`${process.env.PUBLIC_URL}/settings/profile`} component={Profile} />

                            </App>
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));