import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as otherApi from './../../api/other';

import country_code_data from './country-calling-codes.json'

export class AddNetwork extends Component {

    constructor(props) {
        super(props)

        this.state = {
            networks: [],
            name: '',
            handler: '',
            contact: '',
            contact2: '',
            countrycode: '',
            countrycode2: ''
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.fetchNetworks()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ networks: res.data })
                    } else {
                        alert('Error Occured While Fetching the networks.. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured While Fetching the networks.. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    addNetwork = async (e) => {
        e.preventDefault();

        let spinner = document.getElementById("spinneranimid");
        spinner.style.display = "block";

        const networkData = {
            name: this.state.name,
            handler: this.state.handler,
            contact: this.state.contact == '' ? null : '+' + this.state.countrycode + ' ' + this.state.contact,
            contact2: this.state.contact2 == '' ? null : '+' + this.state.countrycode2 + ' ' + this.state.contact2,
        }

        if (this.state.contact == '' && this.state.contact2 == '') {
            alert('Please enter the contact number..!')
            spinner.style.display = "none";
        } else if (this.state.contact != '' && this.state.countrycode == '') {
            alert('Please select country code !')
            spinner.style.display = "none";
        } else if (this.state.contact2 != '' && this.state.countrycode2 == '') {
            alert('Please select country code !')
            spinner.style.display = "none";
        } else {
            if (window.confirm('Do you want to add the Network ?')) {
                await otherApi.addNetwork(networkData)
                    .then(res => {
                        if (res.status == 200) {
                            alert('Network Added ..!')
                            window.location.reload();
                            spinner.style.display = "none";
                        } else {
                            alert('Error Occured While Adding the Network.. Please Try after some time')
                            window.location.reload();
                            spinner.style.display = "none";
                        }
                    })
                    .catch((err) => {
                        alert('Error Occured While Adding the Network.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    })
            } else {
                spinner.style.display = "none";
            }
        }

    }

    deleteNetwork = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Do you want to delete the Network ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            await otherApi.deleteNetwork({ id: id })
                .then((res) => {
                    if (res.status == 200) {
                        alert("Network Deleted");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Network Does Not Exists') {
                        alert('This Network does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured While Deleting the Network.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured While Deleting the Network.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Manage Network" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <form onSubmit={this.addNetwork}>
                                        <label htmlFor='name'>Location :</label>
                                        <input style={{ maxWidth: '350px' }} className="form-control mb-4" id="name" name="name" onChange={(e) => this.setState({ name: e.target.value })} required />

                                        <label htmlFor='handler'>First & Last Name :</label>
                                        <input style={{ maxWidth: '350px' }} className="form-control mb-4" id="handler" name="handler" onChange={(e) => this.setState({ handler: e.target.value })} required />

                                        <label htmlFor='contact'>Contact Number :</label>
                                        <div className='mb-4' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <select style={{ maxWidth: 'max-content' }} className="form-control mr-2" id="countrycode" name="countrycode" onChange={(e) => this.setState({ countrycode: e.target.value })}>
                                                <option value="">-- Select --</option>
                                                {country_code_data.map((item, index) =>
                                                    <option key={index} value={item.callingCode}>{item.name} - {item.callingCode}</option>
                                                )}
                                            </select>
                                            <input type='number' style={{ maxWidth: '350px' }} className="form-control" id="contact" name="contact" onChange={(e) => this.setState({ contact: e.target.value })} />
                                            <h4 className='mb-0 ml-2'>+{this.state.countrycode} {this.state.contact}</h4>
                                        </div>

                                        <label htmlFor='contact2'>Foreign Contact Number :</label>
                                        <div className='mb-4' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <select style={{ maxWidth: 'max-content' }} className="form-control mr-2" id="countrycode2" name="countrycode2" onChange={(e) => this.setState({ countrycode2: e.target.value })}>
                                                <option value="">-- Select --</option>
                                                {country_code_data.map((item, index) =>
                                                    <option key={index} value={item.callingCode}>{item.name} - {item.callingCode}</option>
                                                )}
                                            </select>
                                            <input type='number' style={{ maxWidth: '350px' }} className="form-control" id="contact2" name="contact2" onChange={(e) => this.setState({ contact2: e.target.value })} />
                                            <h4 className='mb-0 ml-2'>+{this.state.countrycode2} {this.state.contact2}</h4>
                                        </div>

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Add Network
                                        </button>

                                    </form>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.networks.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Location</th>
                                                    <th>Name</th>
                                                    <th>Contact</th>
                                                    <th>Foreign Contact</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.networks.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.handler}</td>
                                                        <td>{item.contact ? item.contact : ''}</td>
                                                        <td>{item.contact2 ? item.contact2 : ''}</td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteNetwork(e, item.id)} className='aicon'><i className="fa fa-close" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Network has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default AddNetwork;
