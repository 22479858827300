import shop from "../api";
import * as types from "../constants/ActionTypes";

import "react-toastify/dist/ReactToastify.min.css";

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const receiveCategoriesCustom = (cats) => ({
  type: types.RECEIVE_CATS,
  cats,
});

export const receiveFilterclrsCustom = (filterclrs) => ({
  type: types.RECEIVE_FILTERCLRS,
  filterclrs,
});

export const receiveTrackings = (trackings) => ({
  type: types.RECEIVE_TRACKINGS,
  trackings,
});

export const getAllProducts = () => (dispatch) => {
  dispatch(fetchProductsBegin());
  return shop.getProducts((products) => {
    products.then((data) => {
      dispatch(receiveProducts(data));
    });
  });
};

export const getAllCategoriesCustom = () => (dispatch) => {
  return shop.getCats((cats) => {
    cats.then((data) => {
      dispatch(receiveCategoriesCustom(data));
    });
  });
};

export const getAllFilterColorsCustom = () => (dispatch) => {
  return shop.getFilterclrs((filterclrs) => {
    filterclrs.then((data) => {
      dispatch(receiveFilterclrsCustom(data));
    });
  });
};

export const getAllTrackings = () => (dispatch) => {
  return shop.getTrackings((trackings) => {
    trackings.then((data) => {
      dispatch(receiveTrackings(data));
    });
  });
};

export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});



// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value,
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

export const toggleOrderStatusFilter = (order_status) => ({
  type: types.ORDER_STATUS_FILTER,
  order_status,
});

export const toggleDeleteProductCart = (delete_cart) => ({
  type: types.DELETE_PRODUCT_CART,
  delete_cart,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});
