import React, { Component, Fragment } from 'react'

import { checkUserTokenUtil } from '../../utils/utils';

import Breadcrumb from '../common/breadcrumb';

import * as otherApi from './../../api/other';

export class AddLinks extends Component {

    constructor(props) {
        super(props)

        this.state = {
            links: [],
            title: '',
            descr: '',
            link: '',
        }
    }

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.fetchLinks()
                .then(res => {
                    if (res.status == 200) {
                        this.setState({ links: res.data })
                    } else {
                        alert('Error Occured .. Please Try after some time')
                    }
                    spinner.style.display = "none";
                })
                .catch((err) => {
                    alert('Error Occured .. Please Try after some time')
                    spinner.style.display = "none";
                })
        }
    }

    addLink = async (e) => {
        e.preventDefault();

        if (window.confirm('Do you want to add the Link ?')) {

            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";

            const data = {
                title: this.state.title,
                descr: this.state.descr,
                link: this.state.link,
            }

            await otherApi.addLink(data)
                .then(res => {
                    if (res.status == 200) {
                        alert('Link Added ..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }

    }

    deleteLink = async (e, id) => {
        e.preventDefault();

        if (window.confirm('Do you want to delete the Link ?')) {
            let spinner = document.getElementById("spinneranimid");
            spinner.style.display = "block";
            await otherApi.deleteLink({ id: id })
                .then((res) => {
                    if (res.status == 200) {
                        alert("Link Deleted");
                        window.location.reload();
                        spinner.style.display = "none";
                    } else if (res.status == 405 && res.error == 'Link Does Not Exists') {
                        alert('This Link does not exists..!')
                        window.location.reload();
                        spinner.style.display = "none";
                    } else {
                        alert('Error Occured.. Please Try after some time')
                        window.location.reload();
                        spinner.style.display = "none";
                    }
                })
                .catch((err) => {
                    alert('Error Occured.. Please Try after some time')
                    window.location.reload();
                    spinner.style.display = "none";
                })
        }
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Manage Links" parent="Utilities" />

                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <form onSubmit={this.addLink}>
                                        <label htmlFor='title'>Title* :</label>
                                        <input style={{ maxWidth: '450px' }} className="form-control mb-4" id="title" name="title" onChange={(e) => this.setState({ title: e.target.value })} required />
                                        <label htmlFor='descr'>Description : (optional)</label>
                                        <textarea style={{ maxWidth: '450px' }} className="form-control mb-4" id="descr" name="descr" onChange={(e) => this.setState({ descr: e.target.value })} />
                                        <label htmlFor='link'>Link* :</label>
                                        <input style={{ maxWidth: '450px' }} className="form-control mb-4" id="link" name="link" onChange={(e) => this.setState({ link: e.target.value })} required />

                                        <button type="submit" className="btn btn-primary mt-3">
                                            Add Link
                                        </button>

                                    </form>
                                </div>
                                <div className="card-body order-datatable lists-custom">

                                    {this.state.links.length > 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Link</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.links.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td style={{ textAlign: 'start' }}>{item.descr}</td>
                                                        <td><a href={item.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: '14px' }} className='alink'>{item.link}</a></td>
                                                        <td>
                                                            <a href='#' onClick={(e) => this.deleteLink(e, item.id)} className='aicon'><i className="fa fa-close" /></a>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        :
                                        <div>
                                            <p>No Link has been Added Yet.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )

    }
}

export default AddLinks;
