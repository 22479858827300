import axios from 'axios';

export const createAdminUser = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/createadminuser`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const adminLogin = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/adminlogin`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchAdminUsers = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/fetchadminusers`, {})
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateAdminUser = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/authrequest/updateadminuser`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};