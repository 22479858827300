import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { XCircle } from 'react-feather';

import { ntc } from './ntc'

import Breadcrumb from "./../common/breadcrumb";

import dummyPreview from "./../../assets/images/dummy-preview.jpg";
import user from "./../../assets/images/user.png";

import * as productApi from "./../../api/product";

import { checkUserTokenUtil } from "../../utils/utils";

export class AddProduct extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      sku: '',
      name: '',
      ratings: 5,
      price: 0,
      off: 0,
      shortDesc: '',
      stock6: 0,
      stock9: 0,
      stock11: 0,
      color: '',
      colorName: "",
      description: '',
      additionalInfo: '',
      category: 'KHAN SAREE',
      filterclrs: 'Black',
      new: false,
      imgfiles: [
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      bigPreview: dummyPreview,
      dummyimgs: [
        { img: user },
        { img: user },
        { img: user },
        { img: user },
        { img: user },
        { img: user }
      ],
    };
  };

  componentDidMount = async () => {
    await checkUserTokenUtil();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeColor = e => {
    var n_match = ntc.name(e.target.value);
    var n_rgb = n_match[0]; // RGB value of closest match
    var n_name = n_match[1]; // Text string: Color name
    var n_exactmatch = n_match[2]; // True if exact color match
    this.setState({ colorName: n_name, color: n_rgb })
  }

  _handleImgChange = (e, i) => {
    e.preventDefault();

    let file = e.target.files[0];
    const { dummyimgs } = this.state;
    const { imgfiles } = this.state;

    if (file.type.toLowerCase() == 'image/jpg' || file.type.toLowerCase() == 'image/jpeg' || file.type.toLowerCase() == 'image/png') {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result == 'data:') {
          alert('This image is currupted')
        } else {
          dummyimgs[i].img = reader.result;
          imgfiles[i] = file
          this.setState({
            dummyimgs,
            imgfiles,
            bigPreview: reader.result,
          });
        }
      };
      reader.onerror = () => {
        alert('Error Occured')
      };
    } else {
      alert('Please select the image file..!')
    }
  }

  deleteImage = (e, i) => {
    e.preventDefault();
    const { dummyimgs } = this.state;
    const { imgfiles } = this.state;

    dummyimgs[i].img = user;
    imgfiles[i] = {}
    this.setState({
      dummyimgs,
      imgfiles,
    }, () => {
      for (let j = 0; j < this.state.imgfiles.length; j++) {
        if (this.state.imgfiles[j].size != undefined) {
          var file = this.state.imgfiles[j]
        }
      }
      if (file == undefined) {
        this.setState({
          bigPreview: dummyPreview,
        });
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.setState({
            bigPreview: reader.result,
          });
        }
        reader.onerror = () => {
          alert('Error Occured')
        };
      }
    });
  }

  handleValidSubmit = async (e) => {
    e.preventDefault();

    let nonEmptyimgFiles = []
    for (let j = 0; j < this.state.imgfiles.length; j++) {
      if (this.state.imgfiles[j].size != undefined) {
        nonEmptyimgFiles.push(this.state.imgfiles[j])
      }
    }

    var valueArr = nonEmptyimgFiles.map((item) => { return item.name });
    var isDuplicate = valueArr.some((item, idx) => {
      return valueArr.indexOf(item) != idx
    });
    if (isDuplicate) {
      alert('You selected same files.. Please choose different one..')
    } if (this.state.colorName.toString().includes('Invalid Color')) {
      alert('Please enter correct color Hex Code ..!')
    } else {

      let spinner = document.getElementById("spinneranimid");
      spinner.style.display = "block";

      if (window.confirm('Add Product ?')) {

        const newProd = {
          sku: this.state.sku,
          name: this.state.name,
          ratings: this.state.ratings,
          price: this.state.price,
          off: this.state.off,
          shortDesc: this.state.shortDesc,
          variants: [{ size: 6, stock: parseInt(this.state.stock6) }],
          color: this.state.color,
          colorName: this.state.colorName,
          description: this.state.description,
          additionalInfo: this.state.additionalInfo,
          category: this.state.category,
          filterclrs: this.state.filterclrs,
          new: this.state.new,
        };

        const formData = new FormData();
        for (let k = 0; k < nonEmptyimgFiles.length; k++) {
          formData.append('files', nonEmptyimgFiles[k])
        }
        formData.append('newProd', JSON.stringify(newProd))

        await productApi.addProduct(formData)
          .then(res => {
            if (res.status == 200) {
              alert('Product Added')
              window.location = process.env.PUBLIC_URL + "/products/product-list";
            } else {
              alert('Something went wrong')
              spinner.style.display = "none";
            }
          })
          .catch((err) => {
            alert('Something went wrong')
            spinner.style.display = "none";
          })

      } else {
        spinner.style.display = "none";
      }

    }

  };

  ResetForm() {
    if (window.confirm('Reset Form?')) window.location.reload();
  }

  render() {

    return (
      <Fragment>
        <Breadcrumb title="Add Product" parent="Physical" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                  </div>
                  <div className="row product-adding">
                    <div className="col-xl-5">
                      <div className="add-product">
                        <div className="row">
                          <div className="col-xl-9 xl-50 col-sm-6 col-9">
                            <img
                              src={this.state.bigPreview}
                              alt=""
                              className="img-fluid image_zoom_1 blur-up lazyloaded"
                            />
                          </div>
                          <div className="col-xl-3 xl-50 col-sm-6 col-3">
                            <ul className="file-upload-product">
                              {this.state.dummyimgs.map((res, i) => {
                                return (
                                  <li key={i}>
                                    <div className="box-input-file">
                                      <input
                                        required
                                        className="upload"
                                        type="file"
                                        single="true"
                                        onChange={(e) =>
                                          this._handleImgChange(e, i)
                                        }
                                      />
                                      <img
                                        src={res.img}
                                        style={{ width: 50, height: 50 }}
                                      />
                                    </div>
                                    <div style={{ color: '#f5655b', cursor: 'pointer', height: 'fit-content', zIndex: 10 }} onClick={(e) => this.deleteImage(e, i)}><XCircle style={{ zIndex: 9 }} /></div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7" style={{ marginTop: 30 }}>
                      <form onSubmit={this.handleValidSubmit}>
                        <div className="form form-label-center">

                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Product Sku :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control"
                                name="sku"
                                id="sku"
                                type="text"
                                placeholder="Enter Product Sku"
                                required
                                value={this.state.sku}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Product Name :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control"
                                name="name"
                                id="name"
                                type="text"
                                placeholder="Enter Product Name"
                                required
                                value={this.state.name}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Price :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="price"
                                id="price"
                                type="number"
                                min="1"
                                required
                                placeholder="Price in ₹"
                                value={this.state.price}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Short Description :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control"
                                name="shortDesc"
                                id="shortDesc"
                                type="text"
                                placeholder="Short Description"
                                // required
                                value={this.state.shortDesc}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Stock (6 Yards) :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <input
                                className="form-control mb-0"
                                name="stock6"
                                id="stock6"
                                type="number"
                                placeholder="Enter Stock (For size:6 Yards)"
                                required
                                value={this.state.stock6}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3 row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Color :
                            </label>
                            <div className="col-xl-8 col-sm-7" style={{ display: 'flex', justifyContent: 'space-around' }}>
                              <input
                                className="form-control"
                                name="colorName"
                                id="colorName"
                                type="text"
                                placeholder="Color Name"
                                required
                                value={this.state.colorName}
                                style={{ backgroundColor: this.state.color }}
                                // onChange={this.onChange}
                                readOnly
                              />
                              <input
                                className="form-control"
                                name="color"
                                id="color"
                                type="text"
                                placeholder="Enter Color Hex Code"
                                required
                                // value={this.state.color}
                                onChange={this.onChangeColor}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4">
                              Full Description :
                            </label>
                            <div className="col-xl-8 col-sm-7 description-sm">
                              <textarea
                                className="form-control mb-0"
                                name="description"
                                id="description"
                                type="text"
                                value={this.state.description}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4">
                              Additional Information :
                            </label>
                            <div className="col-xl-8 col-sm-7 description-sm">
                              <textarea
                                className="form-control mb-0"
                                name="additionalInfo"
                                id="additionalInfo"
                                type="text"
                                value={this.state.additionalInfo}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Category :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <select
                                required
                                className="form-control mb-0"
                                name="category"
                                id="category"
                                value={this.state.category}
                                onChange={this.onChange}
                              >
                                {this.props.cats.map((category, index) =>
                                  <option key={index}>{category.category}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-sm-4 mb-0">
                              Filter Color :
                            </label>
                            <div className="col-xl-8 col-sm-7">
                              <select
                                required
                                className="form-control mb-0"
                                name="filterclrs"
                                id="filterclrs"
                                value={this.state.filterclrs}
                                onChange={this.onChange}
                              >
                                {this.props.filterclrs.map((filterclrs, index) =>
                                  <option key={index}>{filterclrs.prdColor}</option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="offset-xl-3 offset-sm-4">
                          <button type="submit" className="btn btn-primary">
                            Add Product
                          </button>
                          <button type="button" className="btn btn-light" onClick={this.ResetForm}>
                            Reset
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: state.data.symbol,
  cats: state.data.cats,
  filterclrs: state.data.filterclrs
})

export default connect(mapStateToProps)(AddProduct);