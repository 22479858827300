import React, { Component, Fragment } from 'react';
import { UserCheck, Box, MessageSquare } from 'react-feather';
import axios from 'axios';

import Breadcrumb from './../common/breadcrumb';

import { checkUserTokenUtil } from '../../utils/utils';

export class SmsService extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clientData: {},
            rechargeData: [],
        }
    };

    componentDidMount = async () => {
        const checkUserTokenUtilRes = await checkUserTokenUtil();
        if (checkUserTokenUtilRes) {
            if (checkUserTokenUtilRes.authority != 'admin') {
                alert('You are not authorised..!')
                this.props.history.push(`/settings/profile`)
            } else {
                let spinner = document.getElementById("spinneranimid");
                spinner.style.display = "block";

                const data = {
                    secret_key: '$2b$10$rmmCdzKGRMv6SwBM0IXCZO/gerswFCEfyuDg41Nk79jOa8hISw7mG'
                }
                await axios.post(`https://api.reecraft.com/smsgatewayrequest/getclient`, data)
                    .then(res => {
                        if (res.data.status == 'success') {
                            this.setState({ clientData: res.data.data.clientData, rechargeData: res.data.data.rechargeData }, () => {
                                spinner.style.display = "none";
                            })
                        } else {
                            alert('something went wrong')
                            spinner.style.display = "none";
                        }
                    })
                    .catch(err => {
                        alert('something went wrong')
                        spinner.style.display = "none";
                    })
            }
        }
    }

    render() {
        return (

            <Fragment>
                <Breadcrumb title="SMS Service" parent="Dashboard" />
                <div id="spinneranimid" className="overlay-spinner">
                    <div className="cssload-whirlpool" />
                </div>
                <div className="container-fluid">

                    <div className='row'>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-md-6">
                            <div className="card o-hidden widget-cards">
                                <div className="bg-cust card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-3">
                                            <div className="align-self-center text-center"><UserCheck className="font-cust" /></div>
                                        </div>
                                        <div className="media-body col-9">
                                            <h5 className="mb-1"><span className='font-koho'>Name: </span><strong className='font-koho'>{this.state.clientData.client_name}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>Customer ID: </span><strong className='font-koho'>{this.state.clientData.customer_id}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>Headers: </span><strong className='font-koho'>{this.state.clientData.headers}</strong></h5>
                                            <h5 className="mb-0"><span className='font-koho'>Joining Date: </span><strong className='font-koho'>{new Date(parseInt(this.state.clientData.createdAt)).toDateString()}</strong></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-md-6">
                            <div className="card o-hidden  widget-cards">
                                <div className="bg-secondary card-body">
                                    <div className="media static-top-widget row">
                                        <div className="icons-widgets col-3">
                                            <div className="align-self-center text-center"><Box className="font-secondary" /></div>
                                        </div>
                                        <div className="media-body col-9">
                                            <h5 className="mb-1"><span className='font-koho'>Total Recharge: </span><strong className='font-koho'>₹ {this.state.clientData.recharge_amount}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>SMS Rate: </span><strong className='font-koho'>₹ {parseInt(this.state.clientData.sms_rate) / 100}</strong></h5>
                                            <h5 className="mb-1"><span className='font-koho'>Total SMS: </span><strong className='font-koho'>{this.state.clientData.total_sms}</strong></h5>
                                            <h5 className="mb-0"><span className='font-koho'>Remaining SMS: </span><strong className='font-koho'>{this.state.clientData.total_sms - this.state.clientData.used_sms}</strong></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className='text-center mt-3 mb-4 font-koho'>SMS Recharges</h2>
                    <div className="row">
                        {this.state.rechargeData.length ?
                            this.state.rechargeData.map((recharge, index) =>
                                <div className="col-xl-4 col-lg-6 col-sm-12 col-md-6" key={index}>
                                    <div className="card order-graph sales-carousel">
                                        <div className="card-header">
                                            <h6>{new Date(parseInt(recharge.recharge_date)).toDateString()}</h6>
                                            <h3 className="font-koho">₹ <strong>{recharge.recharge_amount}</strong></h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="media">
                                                <div className="media-body">
                                                    <span className='font-koho'>SMS Requested</span>
                                                    <h2 className="mb-0 font-koho font-secondary">{recharge.total_sms}</h2>
                                                    <p className='font-koho'>₹ {parseInt(recharge.sms_rate) / 100} <span> per hit</span></p>
                                                    <h5 className="f-w-600 f-16 font-koho mb-0">Paid Amount :</h5>
                                                    <h6 className="f-w-600 f-16" style={{ color: 'green' }}>₹ {recharge.recharge_amount}</h6>
                                                </div>
                                                <div className="bg-primary b-r-8">
                                                    <div className="small-box bg-cust" style={{ borderRadius: 'inherit' }}>
                                                        <MessageSquare />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            <div>No Recharges Yet</div>
                        }

                    </div>
                </div>
            </Fragment>

        )
    }
}
// javascript:void(0)

export default SmsService
